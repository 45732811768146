import {useCreateUpdateParametersJob} from "../../../../../../../api/useCreateUpdateParametersJob";
import {useCallback, useState} from "react";

export function useUpdateParametersLogic() {
    const [comparisonJobId, setComparisonJobId] = useState<string | null>(null);
    const [doCreateJob, {loading, success, error}] = useCreateUpdateParametersJob();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const openDialog = useCallback((jobId: string) => {
        setComparisonJobId(jobId);
        setDialogIsOpen(true);
    }, []);

    const closeDialog = useCallback(() => {
        setComparisonJobId(null);
        setDialogIsOpen(false);
    }, []);

    const createJob = useCallback(() => {
        doCreateJob(comparisonJobId).then(() => {
            closeDialog();
        });
    }, [closeDialog, comparisonJobId, doCreateJob]);

    return {dialogIsOpen, openDialog, closeDialog, createJob, updatingParameters: loading, error, success};
}
