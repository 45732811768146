import {Redirect, Route, Switch} from "react-router-dom";
import {LINKS} from "../../links";
import {ParameterAgeMetrics} from "./pages/ParameterAgeMetrics";
import {NewRecalculationJob} from "./pages/NewRecalculationJob";
import {RecalculationJobs} from "./pages/RecalculationJobs";

export const Recalculation = () => (
    <Switch>
        <Route exact path={LINKS.recalculation()}>
            <div>
                <ParameterAgeMetrics/>
                <RecalculationJobs/>
            </div>
        </Route>
        <Route exact path={LINKS.newRecalculationJob()}>
            <NewRecalculationJob/>
        </Route>
        <Route path="*">
            <Redirect to={LINKS.recalculation()}/>
        </Route>
    </Switch>
);
