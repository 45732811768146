import {Fragment} from "react";
import {UpdateParametersJobProgress} from "../../../../../../../../api/types/UpdateParametersJobProgress";

interface Props {
    progress: UpdateParametersJobProgress | null;
}

export function ActiveUpdateParametersJobProgress({progress}: Props) {
    if (!progress) {
        return null;
    }

    return (
        <Fragment>
            <h3>Active update parameters job progress:</h3>
            <div style={{width: 300, margin: '0 auto'}}>
                <p>{progress.processedParameterCount} / {progress.totalParameterCount} questions processed</p>
                <p>{progress.percentageProgress}% completed</p>
            </div>
        </Fragment>
    );
}
