import {useMemo} from "react";
import {useApiCall} from "./useApiCall";

interface UseAvailableTableIdsReturn {
    tableIds: string[];
    loading: boolean;
    error: string | null;
    refetch: () => void;
}

export function useAvailableTableIds(): UseAvailableTableIdsReturn {
    const url = `/api/parameters.listAvailableTableIds`;
    const {data, error, loading, refetch} = useApiCall(url);

    const tableIds = useMemo(() => {
        if (data) {
            return data.tableIds;
        }
        return [];
    }, [data]);

    return {
        tableIds,
        loading,
        error,
        refetch
    };
}
