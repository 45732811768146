import MenuItem from "@material-ui/core/MenuItem";
import {Filter} from "../../../../../components/Filter";
import {FACETS, LOCALES} from "./ComparisonJobResults/constants";
import styled from "styled-components";

interface Props {
    facet: string;
    locale: string;
    selectFacet: (e: any) => void;
    selectLocale: (e: any) => void;
    clearFacet: () => void;
    clearLocale: () => void;
}

export function LocaleAndFacetFilter({facet, locale, selectFacet, selectLocale, clearFacet, clearLocale}:Props) {
    return (
        <FilterBox>
            <Filter
                filterId={'select-facet'}
                label={'Facet: '}
                onChange={selectFacet}
                onClear={clearFacet}
                selectedValue={facet}
            >
                {FACETS.map((facet: string) => (
                    <MenuItem key={`facet-option-${facet}`} value={facet}>{facet}</MenuItem>
                ))}
            </Filter>
            <Filter
                filterId={'select-locale'}
                label={'Locale: '}
                onChange={selectLocale}
                onClear={clearLocale}
                selectedValue={locale}
            >
                {Object.entries(LOCALES).map((entry) => (
                    <MenuItem key={`locale-option-${locale}`} value={entry[0]}>{entry[1]}</MenuItem>
                ))}
            </Filter>
        </FilterBox>
    )
}

const FilterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;


