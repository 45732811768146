import {Redirect, Route, Switch} from "react-router-dom";
import styled from "styled-components";
import {Navbar} from "./Navbar";
import {LINKS} from "./links";
import {Parameters} from "./pages/Parameters";
import {Recalculation} from "./pages/Recalculation";
import {Locales} from "./pages/Locales";
import {Questions} from "./pages/Questions";

export const Home = () => (
    <Wrapper>
        <Navbar/>
        <Content>
            <Switch>
                <Route path={LINKS.parameters()}>
                    <Parameters/>
                </Route>
                <Route path={LINKS.recalculation()}>
                    <Recalculation/>
                </Route>
                <Route path={LINKS.locales()}>
                    <Locales/>
                </Route>
                <Route path={LINKS.questions()}>
                    <Questions/>
                </Route>
                <Route path="">
                    <Redirect to={LINKS.parameters()}/>
                </Route>
            </Switch>
        </Content>
    </Wrapper>
);

const Wrapper = styled.div`
  position: relative;
`;

const Content = styled.div`
  position: absolute;
  top: 80px;
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  width: 100%;
`;
