import {useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';

import {parseUrl} from '../config';
import {buildRequestHeaders, clearToken} from '../services/auth/helpers';
import {formatError} from './utils';

type ApiOperationStatus = {
    data?: any;
    loading: boolean;
    success: boolean;
    error: string | null;
}

type ApiOperationOptions = {
    onCompleted?: () => void;
    onError?: () => void;
    method?: string;
}

type OperationReturn = [
    (data: any | null, resourceId?: string) => Promise<unknown>,
    ApiOperationStatus
];

type StatusType = {
    loading: boolean;
    error: string | null;
    success: boolean;
    data: object | null;
};

export const useApiOperation = (
    url: string,
    options: ApiOperationOptions = {}
): OperationReturn => {
    const [status, setStatus] = useState<StatusType>({
        loading: false,
        error: null,
        success: false,
        data: null
    });
    const history = useHistory();

    const makeApiCall = useCallback(
        (data: object | null, resourceId: string | null) => new Promise((resolve, reject) => {
            setStatus({loading: true, error: null, success: false, data: null});
            const fetchOptions = {
                method: options.method || 'POST',
                headers: buildRequestHeaders(),
                body: data ? JSON.stringify(data) : null
            };

            const fullUrl = resourceId ? `${url}/${resourceId}` : url;

            return fetch(parseUrl(fullUrl), fetchOptions)
                .then(res => res.json())
                .then(response => {
                    if (response.status === 403) {
                        clearToken();
                        history.push('/login');
                    } else if (response.data) {
                        setStatus({
                            loading: false,
                            error: null,
                            success: true,
                            data: response.data
                        });
                        if (options && options?.onCompleted) {
                            options.onCompleted();
                        }
                        resolve(response.data);
                    } else {
                        const errorStr = formatError(response.error);
                        console.log(errorStr)
                        setStatus({
                            loading: false,
                            error: errorStr,
                            success: false,
                            data: null
                        });
                        if (options && options?.onError) {
                            options.onError();
                        }
                        reject(errorStr);
                    }
                })
                .catch(error => {
                    console.log(error);
                    setStatus({
                        loading: false,
                        error: error.toString(),
                        success: false,
                        data: null
                    });
                    if (options && options?.onError) {
                        options.onError();
                    }
                });
        }),
        [url, history, options]
    );

    return [
        makeApiCall as (data: any, resourceId?: string | null) => Promise<unknown>,
        {
            loading: status.loading,
            success: status.success,
            error: status.error,
            data: status.data
        } as ApiOperationStatus
    ];
}
