import {useCallback, useState} from "react";

export function useDatesLogic() {
    const [fromDate, setFromDate] = useState(new Date(2020, 0, 1));
    const [toDate, setToDate] = useState(new Date());
    const [recalculatedBeforeDate, setRecalculatedBeforeDate] = useState(new Date());

    const handleFromDateChange = useCallback((date: any) => {
        setFromDate(date);
    }, []);
    const handleToDateChange = useCallback((date: any) => {
        setToDate(date);
    }, []);
    const handleRecalculatedBeforeDateChange = useCallback((date: any) => {
        setRecalculatedBeforeDate(date);
    }, []);

    return {
        fromDate, toDate, recalculatedBeforeDate,
        handleFromDateChange, handleToDateChange, handleRecalculatedBeforeDateChange
    };
}
