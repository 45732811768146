import {useCallback, useState} from 'react';

export function useLocaleAndStatusFilterLogic() {
    const [status, setStatus] = useState<string>('');
    const [locale, setLocale] = useState<string>('');

    const selectStatus = useCallback((e: any) => {
        setStatus(e.target.value);
    }, []);

    const selectLocale = useCallback((e: any) => {
        setLocale(e.target.value);
    }, []);

    const clearStatus = useCallback(() => {
        setStatus('');
    }, []);

    const clearLocale = useCallback(() => {
        setLocale('');
    }, []);

    return {status, locale, selectStatus, selectLocale, clearStatus, clearLocale};
}
