import {IconButton, InputLabel} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

import styled from "styled-components";
import Select from "@material-ui/core/Select";

interface Props {
    disabled?: boolean;
    filterId: string;
    label: string;
    onChange: (e: any) => void;
    onClear: () => void;
    selectedValue: string;
    children: any;
}

export const Filter = ({disabled=false, filterId, label, onChange, onClear, selectedValue, children}: Props) => (
    <Wrapper>
        <Label id={filterId}>{label}</Label>
        <SelectWrapper
            defaultValue = "" 
            disabled={disabled}
            value={selectedValue}
            onChange={onChange}
            inputProps={{
                id: filterId,
                name: filterId
            }}
        >
            {children}
        </SelectWrapper>
        <IconButton onClick={onClear}>
            <ClearIcon/>
        </IconButton>
    </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 80px;
  &:last-child {
    margin: 0;
  }
`;

const Label = styled(InputLabel)`
  margin-right: 20px;
`;

const SelectWrapper = styled(Select)`
  margin-right: 20px;
  min-width: 150px;
`;
