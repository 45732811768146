import {useMemo} from "react";
import {useApiCall} from "./useApiCall";
import {QuestionDetails} from "./types/QuestionDetails";
import {parseQuestionDetails} from "./parse";

interface CurrentParametersReturn {
    parameters: QuestionDetails[];
    loading: boolean;
    error: string | null;
    refetch: () => void;
}

function buildQueryString( locale?: string, facet?: string): string {
    let queryString = ``;
    if (!locale && !facet) {
        return queryString;
    }

    if (!locale) {
        return queryString + `facet=${facet}`;
    }

    if (!facet) {
        return queryString + `locale=${locale}`;
    }

    return queryString + `&locale=${locale}&facet=${facet}`;
}

export function useCurrentParameters(locale: string, facet: string): CurrentParametersReturn {
    const baseUrl = `/api/parameters.list`;
    const queryString = buildQueryString( locale, facet);
    const url = `${baseUrl}?${queryString}`;
    const skipRequest = !locale && !facet
    const {data, error, loading, refetch} = useApiCall(url, skipRequest);

    const parameters = useMemo(() => {
        if (data) {
            return parseQuestionDetails(data);
        }
        return [];
    }, [data]);

    return {
        parameters,
        loading,
        error,
        refetch
    };
}
