import styled from "styled-components";
import {ParameterComparisonResults} from "./components/ParameterComparisonResults";
import {ParameterComparisonSummary} from "./components/ParameterComparisonSummary";
import {useFilterLogic} from "./hooks/useFilterLogic";
import {LocaleAndFacetFilter} from "../LocaleAndFacetFilter";

interface Props {
    comparisonJobId: string;
}

export function ComparisonJobResults({comparisonJobId}: Props) {
    const {facet, locale, selectFacet, selectLocale, clearFacet, clearLocale} = useFilterLogic();

    return (
        <div>
            <h2>Breakdown of parameter comparison for selected job</h2>
            <h3>You can choose to filter by facet and locale (or one of them)</h3>
            <br/>
            <FlexBox>
                <LocaleAndFacetFilter
                        facet={facet}
                        locale={locale}
                        selectFacet={selectFacet}
                        selectLocale={selectLocale}
                        clearFacet={clearFacet}
                        clearLocale={clearLocale}
                    />
            </FlexBox>

            <ParameterComparisonSummary comparisonJobId={comparisonJobId} locale={locale} facet={facet}/>
            {(locale || facet) ? (
                <ParameterComparisonResults comparisonJobId={comparisonJobId} locale={locale} facet={facet}/>
            ) : (
                <p>Results will be displayed when you have selected at least one facet or locale</p>
            )}
        </div>
    )
}

const FlexBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;
