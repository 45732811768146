import {useCallback, useMemo} from "react";
import {QuestionDetails} from "../../../../../../../api/types/QuestionDetails";
import {
    globalIdSortComparator,
    parameterValueFormatter,
    renderPositiveKey
} from "../../../utils/tableUtils";
import {getCommonColumnProperties} from "../../../../../utils/tableUtils";
import {v4 as uuid} from "uuid";

export function useTableLogic(parameters: QuestionDetails[]) {
    const columns = useMemo(() => {
        return [
            {field: 'globalId', sortComparator: globalIdSortComparator},
            {field: 'updatedAt', type:'date'},
            {field: 'locale'},
            {field: 'facet'},
            {field: 'status'},
            {field: 'alpha', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'kappa1', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'kappa2', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'kappa3', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'kappa4', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'alphaSigma', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'kappa1Sigma', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'kappa2Sigma', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'kappa3Sigma', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'kappa4Sigma', type: 'number', valueFormatter: parameterValueFormatter},
            {field: 'positiveKey', type: 'boolean', renderCell: renderPositiveKey}
        ].map(cell => ({...getCommonColumnProperties(150), ...cell}));
    }, []);

    const getRows = useCallback(() => {
        return parameters.map((p: QuestionDetails) => {
            return Object.assign({'id': uuid()}, p)
        });
    }, [parameters]);

    return {columns, getRows};
}
