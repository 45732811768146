import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import PowerCharts from "fusioncharts/fusioncharts.powercharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import { FC } from "react";


ReactFC.fcRoot(FusionCharts, Charts, PowerCharts, FusionTheme);

export const Chart = ReactFC as unknown as FC<any>;

