import {Fragment} from 'react';
import {Button, CircularProgress} from '@material-ui/core';
import {Redirect} from "react-router-dom";
import {LINKS} from '../../../../../../links';
import {secondsToDaysHoursMinutesSeconds} from "../../../../../../../../utils";
import {useCreateRecalculationJobLogic} from "./hooks/useCreateRecalculationJobLogic";
import {useFormatDatesLogic} from "./hooks/useFormatDatesLogic";
import styled from "styled-components";


interface Props {
    fromDate: string | Date;
    toDate: string | Date;
    recalculatedBeforeDate: string | Date;
    isDisabled: boolean;
}

export function CreateRecalculationJob({fromDate, toDate, recalculatedBeforeDate, isDisabled}: Props) {
    const {
        showCreateJob,
        previewingJob,
        creatingJob,
        totalTests,
        recalculationJobId,
        previewJob,
        createRecalculationJob
    } = useCreateRecalculationJobLogic({fromDate, toDate, recalculatedBeforeDate});
    const {
        formattedFromDate,
        formattedToDate,
        formattedRecalculatedBeforeDate,
        allDatesAreSet
    } = useFormatDatesLogic({fromDate, toDate, recalculatedBeforeDate});

    if (recalculationJobId) {
        return <Redirect to={LINKS.recalculation()}/>
    }

    if (showCreateJob) {
        return (
            <div>
                {totalTests !== null && (
                    <div>
                        This job will recalculate {totalTests} tests.
                        {totalTests > 0 && (
                            <div>
                                <p>
                                    Estimated recalculation job length is {secondsToDaysHoursMinutesSeconds(totalTests)}.
                                </p>
                                <i>The estimated recalculation job length may be off due to uncompleted tasks from
                                    previous running jobs</i>
                            </div>
                        )}
                    </div>
                )}
                <ButtonWrapper>
                    <Button
                        onClick={createRecalculationJob}
                        variant="contained"
                        disabled={creatingJob || isDisabled || totalTests === 0}
                    >
                        {creatingJob ? <CircularProgress size={23.5}/> : "Start job!"}
                    </Button>
                </ButtonWrapper>
            </div>
        );
    }

    return (
        <div>
            <DateInfoWrapper>
                {allDatesAreSet ? (
                    <Fragment>
                        <b>Including tests that are:</b>
                        <List>
                            <ListItem>Completed between {formattedFromDate} to {formattedToDate}</ListItem>
                            <ListItem>Recalculated before {formattedRecalculatedBeforeDate}</ListItem>
                        </List>
                    </Fragment>
                ) : null}
            </DateInfoWrapper>
            <ButtonWrapper>
                <Button
                    onClick={previewJob}
                    variant="contained"
                    disabled={previewingJob}
                >
                    {previewingJob ? <CircularProgress size={23.5}/> : "Preview!"}
                </Button>
            </ButtonWrapper>
        </div>
    );
}

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

const DateInfoWrapper = styled.div`
  width: fit-content;
  margin: 0 auto;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ListItem = styled.li`
  text-align: center;
  margin-bottom: 4px;
`;
