import {useCallback, useMemo} from "react";
import {useApiOperation} from "./useApiOperation";


interface PreviewRecalculationJobStatus {
    totalTests: number | null;
    loading: boolean;
    error: string | null;
}

type Return = [
    (after: string | Date, before: string | Date, recalculatedBefore: string | Date) => Promise<unknown>,
    PreviewRecalculationJobStatus
];


export function usePreviewRecalculationJob(): Return {
    const url = '/api/recalculationJobs.preview';
    const [request, {data, error, loading}] = useApiOperation(url);

    const totalTests = useMemo(() => {
        if (data) {
            return data.totalTests;
        }
        return null;
    }, [data]);

    const makeRequest = useCallback((after: string | Date, before: string | Date, recalculatedBefore: string | Date) => {
        const payload = {
            after,
            before,
            recalculatedBefore
        };
        return request(payload);
    }, [request]);

    return [makeRequest, {
        totalTests,
        loading,
        error
    } as PreviewRecalculationJobStatus]
}
