import {useActiveUpdateParametersJobProgress} from "../../../../../../../../api/useActiveUpdateParametersJobProgress";
import {useCallback, useEffect, useState} from "react";
import {UpdateParametersJobProgress} from "../../../../../../../../api/types/UpdateParametersJobProgress";

export function useActiveUpdateParametersJobProgressLogic(): {progress: UpdateParametersJobProgress | null} {
    const {progress, loading, refetch} = useActiveUpdateParametersJobProgress();
    const [progressInState, setProgressInState] = useState<UpdateParametersJobProgress | null>(null);

    const progressHasChanged = useCallback((newProgress: UpdateParametersJobProgress | null) => {
        if (!progressInState || !newProgress) {
            return true;
        }

        return newProgress.percentageProgress !== progressInState.percentageProgress;
    }, [progressInState]);

    const updateProgress = useCallback((newProgress: UpdateParametersJobProgress | null) => {
        if (progressHasChanged(newProgress)) {
            setProgressInState(newProgress);
        }
    }, [progressHasChanged]);

    useEffect(() => {
        const timer = setInterval(refetch, 10000);
        return () => clearInterval(timer);
    }, [refetch]);

    useEffect(() => {
        if (!loading) {
            updateProgress(progress);
        }
    }, [updateProgress, loading, progress]);

    return {progress: progressInState};
}
