export const LOCALES = {
    'EN': 'US English (EN)',
    'EN-GB': 'UK English (EN-GB)',
    'SV': 'Swedish (SV)',
    'DA': 'Danish (DA)',
    'NB': 'Norwegian (NB)',
    'FI': 'Finnish (FI)',
    'DE': 'German (DE)',
    'ES': 'Spanish (ES)',
    'NL': 'Dutch (NL)',
    'LV': 'Latvian (LV)',
    'LT': 'Lithuanian (LT)',
    'ET': 'Estonian (ET)',
    'FR-FR': 'French (FR-FR)',
    'PL': 'Polish (PL)',
    'PT-BR': 'Portuguese (PT-BR)',
    'ZH-CN': 'Mandarin (ZH-CN)',
    'AR': 'Arabic (AR)',
    'IT': 'Italian (IT)',
    'CS': 'Czech (CS)',
    'SK': 'Slovak (SK)',
    'JA': 'Japanese (JA)'
};

export const FACETS = [
    'AI',
    'AII',
    'AIII',
    'CI',
    'CII',
    'CIII',
    'EI',
    'EII',
    'EIII',
    'ESI',
    'ESII',
    'ESIII',
    'OI',
    'OII',
    'OIII'
];
