import {useCallback} from "react";
import {useApiOperation} from "./useApiOperation";
import {Locale} from "./types/Locale";
import { DataCollectionMode } from "../pages/Home/pages/Locales/constants";


interface UpdateDataCollectionForLocaleStatus {
    locale: Locale | null;
    loading: boolean;
    error: string | null;
}

type UpdateDataCollectionStatusForLocaleReturn = [
    (localeId: string, isDataCollectionEnabled: boolean) => Promise<unknown>,
    UpdateDataCollectionForLocaleStatus
];

type UpdateDataCollectionModeForLocaleReturn = [
    (localeId: string, dataCollectionMode?: DataCollectionMode) => Promise<unknown>,
    UpdateDataCollectionForLocaleStatus
];


export function useUpdateDataCollectionStatusForLocale(): UpdateDataCollectionStatusForLocaleReturn {
    const url = '/api/locales.updateDataCollectionStatus';
    const [request, {error, loading}] = useApiOperation(url);

    const makeRequest = useCallback((localeId: string, isDataCollectionEnabled: boolean) => {
        const payload = {localeId, isDataCollectionEnabled};
        return request(payload);
    }, [request]);

    return [makeRequest, {
        loading,
        error
    } as UpdateDataCollectionForLocaleStatus]
}


export function useUpdateDataCollectionModeForLocale(): UpdateDataCollectionModeForLocaleReturn {
    const url = '/api/locales.updateDataCollectionMode';
    const [request, {error, loading}] = useApiOperation(url);

    const makeRequest = useCallback((localeId: string, dataCollectionMode ?: DataCollectionMode) => {
        const payload = {localeId, dataCollectionMode: dataCollectionMode || null};
        return request(payload);
    }, [request]);

    return [makeRequest, {
        loading,
        error
    } as UpdateDataCollectionForLocaleStatus]
}
