import {importQuestionCSVHeaderkeyMap} from './constants';
import {ImportedQuestion} from './types';

export const transformCSVDataToQuestions = (data: string[][]): ImportedQuestion[] => {
    const [headers, ...rows] = data;

    // Check if all keys from keyMap exist in oldKeys
    const missingKeys = Object.keys(importQuestionCSVHeaderkeyMap).filter(
        key => !headers.includes(key)
    );
    // Remove empty rows due to new lines or similar in the csv data
    const cleanRows = rows.filter((row) => {
        return row.some((cell) => cell && cell.trim() !== '');
    });

    if (!missingKeys.length) {
        const newHeaders = headers.map(key => importQuestionCSVHeaderkeyMap[key] || key);
        const questions = cleanRows.map(row => {
            return row.reduce<ImportedQuestion>((acc, value, index) => {
                acc[newHeaders[index] as keyof ImportedQuestion] = value;
                return acc;
                // Default structure for the accumulator, otherwise ts will complain that string[] is not assignable to ImportedQuestion
            }, Object.fromEntries(Object.values(importQuestionCSVHeaderkeyMap).map(key => [key, ''])) as ImportedQuestion);
        });

        // Check for duplicates
        const occurrenceMap = new Map<string, number>();
        questions.forEach(question => {
            const key = `${question.globalId}/${question.locale}`;
            occurrenceMap.set(key, (occurrenceMap.get(key) || 0) + 1);
        });
        const duplicates = Array.from(occurrenceMap.entries())
            .filter(([_, count]) => count > 1)
            .map(([key]) => key);
        if (duplicates.length > 0) {
            console.log('There are duplicate globalId/locale combinations in the questions:', duplicates);
            throw new Error(`Duplicate globalId/locale combinations found: ${duplicates.join(', ')}`);
        }

        return questions
    } else {
        console.log('Not all keys exist in the csv data', missingKeys);
        throw new Error('Missing expected columns: ' + missingKeys);
    }
};
