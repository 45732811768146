import {useMemo} from "react";
import {useApiCall} from "./useApiCall";
import {parseQuestion} from "./parse";
import {Question} from "./types/Question";


interface QuestionsReturn {
    questions: Question[] | null;
    loading: boolean;
    error: string | null;
    refetch: () => void;
}


function buildQueryString(locale: string | null = ''): string {
    return `locale=${locale}`;
}

export function useQuestions(locale?: string | null): QuestionsReturn {
    const baseUrl = `/api/questions.list`;
    const skipRequest = !locale;
    const queryString = buildQueryString(locale);
    const url = `${baseUrl}?${queryString}`;
    const {data, error, loading, refetch} = useApiCall(url, skipRequest);

    const questions = useMemo(() => {
        if (data) {
            return parseQuestion(data);
        }

        return null;
    }, [data]);

    return {questions, error, loading, refetch};
}
