import {useMemo} from "react";
import {useApiCall} from "./useApiCall";
import {parseParameterComparisonSummary} from "./parse";
import {ParameterComparisonSummary} from "./types/ParameterComparisonSummary"
import {buildFilterQueryString} from "./utils";

interface Arguments {
    comparisonJobId: string;
    locale: string;
    facet: string;
}

interface Return {
    parameterComparisonSummary: ParameterComparisonSummary[];
    loading: boolean;
    error: string | null;
    refetch: () => void;
}

export function useParameterComparisonSummary(args: Arguments): Return {
    const baseUrl = `/api/parameterComparisonJobs.getSummary`;
    const {comparisonJobId, locale, facet} = args;
    const queryString = buildFilterQueryString(comparisonJobId, locale, facet);
    const url = `${baseUrl}?${queryString}`;

    const {data, error, loading, refetch} = useApiCall(url);

    const parameterComparisonSummary = useMemo(() => {
        if (data) {
            return parseParameterComparisonSummary(data);
        }
        return [];
    }, [data]);

    return {
        parameterComparisonSummary,
        loading,
        error,
        refetch
    };
}
