export const LINKS = {
    login: () => '/login',
    logout: () => '/logout',
    parameters: () => '/parameters',
    currentParameters: () => '/parameters/current-parameters',
    createComparisonJob: () => '/parameters/create-comparison-job',
    comparisonJobs: () => '/parameters/comparison-jobs',
    comparisonJobResults: (comparisonJobId: string) => `/parameters/comparison-jobs/${comparisonJobId}`,
    recalculation: () => '/recalculation',
    newRecalculationJob: () => '/recalculation/new-job',
    locales: () => '/locales',
    questions: () => '/questions',
    importQuestions: () => '/questions/import',
};
