import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@mui/material';
import {ChangeEvent, FC, useState} from 'react';

export type CreateQuestionFormData = {
    global_id: string;
    locale: string;
    title: string;
};

type CreateQuestionModalProps = {
    open: boolean;
    onClose: () => void;
    onSubmit: (data: CreateQuestionFormData) => void;
};

const CreateQuestionModal: FC<CreateQuestionModalProps> = ({open, onClose, onSubmit}) => {
    const [formData, setFormData] = useState<CreateQuestionFormData>({
        global_id: '',
        locale: '',
        title: ''
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const {name, value} = e.target;
        setFormData(prev => ({...prev, [name]: value}));
    };

    const handleSubmit = () => {
        onSubmit(formData);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Create Question</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    name="global_id"
                    label="Global ID"
                    variant="standard"
                    type="text"
                    fullWidth
                    value={formData.global_id}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    name="locale"
                    label="Locale"
                    variant="standard"
                    type="text"
                    fullWidth
                    value={formData.locale}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    name="title"
                    label="Title"
                    variant="standard"
                    type="text"
                    fullWidth
                    value={formData.title}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="contained">
                    Create Question
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateQuestionModal;
