
import {CreateComparisonJob} from "./components/CreateComparisonJob";
import {CurrentParameters} from "./components/CurrentParameters";
import styled from "styled-components";
import {ComparisonJobs} from "./components/ComparisonJobs";
import {LINKS} from "../../links";
import {NavLink, Redirect, Route, Switch, useParams} from "react-router-dom";
import {ComparisonJobResults} from "./components/ComparisonJobResults";

function RenderComparisonJobResults() {
    const params = useParams();

    // @ts-ignore
    return <ComparisonJobResults comparisonJobId={params.comparisonJobId}/>;
}

export function Parameters() {
    return (
        <Wrapper>
            <TabsWrapper>
                    <Tab exact to={LINKS.currentParameters()} activeClassName={'active'}>
                        Current parameters
                    </Tab>
                    <Tab exact to={LINKS.createComparisonJob()} activeClassName={'active'}>
                        Create comparison job
                    </Tab>
                     <Tab exact to={LINKS.comparisonJobs()} activeClassName={'active'}>
                        Comparison jobs
                    </Tab>
            </TabsWrapper>
            <PageWrapper>
                <Switch>
                    <Route path={LINKS.currentParameters()}>
                        <CurrentParameters/>
                    </Route>
                    <Route path={LINKS.createComparisonJob()}>
                        <CreateComparisonJob/>
                    </Route>
                    <Route exact path={LINKS.comparisonJobResults(':comparisonJobId')}>
                        <RenderComparisonJobResults/>
                    </Route>
                    <Route path={LINKS.comparisonJobs()}>
                        <ComparisonJobs/>
                    </Route>
                    <Route path="">
                        <Redirect to={LINKS.currentParameters()}/>
                    </Route>
                </Switch>
            </PageWrapper>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  flex: 1;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
`;

const TabsWrapper = styled.div`
  flex: 0 0;
  margin-bottom: 40px;
`;

const Tab = styled(NavLink)`
  width: 200px;
  padding: 25px;
  color: #444;
  display: inline-block;
  margin-left: 0;
  padding: 10px;
  border-bottom: 2px solid #eee;
  transition: all .5s;
  font-weight: 500;
  cursor: pointer;
  color: #aaa;
  &.active {
    border-bottom: 2px solid #337ab7;
    color: #444;
  }
  text-decoration: none;
`;

const PageWrapper = styled.div`
  flex: 1 1;
  overflow-y: auto;
`;
