import {useCallback, useEffect, useState} from 'react';
import {usePapaParse} from 'react-papaparse';

import {useCreateQuestion} from '../../../../../../api/useCreateQuestion';
import {useQuestions} from '../../../../../../api/useQuestions';
import {useUpdateQuestion} from '../../../../../../api/useUpdateQuestion';
import {CsvData} from '../../../../../../components/CSVReader/types';
import {importQuestionCSVHeaderkeyMap} from './constants';
import {ImportedQuestion, QuestionSummary} from './types';
import {transformCSVDataToQuestions} from './utils';

export const useImportQuestionLogic = () => {
    const [csvData, setCsvData] = useState<CsvData | null>(null);
    const [progress, setProgress] = useState<number | null>(null);
    const [importedQuestions, setImportedQuestions] = useState<ImportedQuestion[]>([]);
    const [questionsToUpdate, setQuestionsToUpdate] = useState<QuestionSummary[]>([]);
    const [questionsToCreate, setQuestionsToCreate] = useState<QuestionSummary[]>([]);
    const [locale, setLocale] = useState<string | null>(null);
    const [doUpdateQuestion, {error: updateQuestionError}] = useUpdateQuestion();
    const [doCreateQuestion, {error: createQuestionError}] = useCreateQuestion();
    const [error, setError] = useState<string | null>(null);

    const doImportQuestions = useCallback(async () => {
        const normaliseProgress = (value: number) => {
            const totalItems = questionsToUpdate.length + questionsToCreate.length;
            return (value / totalItems) * 100;
        };
        let importedQuestionCount = 0;
        let errorOnUpdate = false;
        for (const question of questionsToUpdate) {
            try {
                if (!question.status) {
                    throw new Error('Question is missing status');
                }
                await doUpdateQuestion(question.id, question.translatedTitle, question.status);
                importedQuestionCount++;
                setProgress(normaliseProgress(importedQuestionCount));
            } catch (error) {
                console.error('Error updating question:', error);
                errorOnUpdate = true;
            }
        }
        if (!errorOnUpdate) {
            for (const question of questionsToCreate) {
                try {
                    await doCreateQuestion(
                        question.globalId,
                        question.locale,
                        question.translatedTitle
                    );
                    importedQuestionCount++;
                    setProgress(normaliseProgress(importedQuestionCount));
                } catch (error) {
                    console.error('Error creating question:', error);
                    break;
                }
            }
        }
    }, [
        questionsToUpdate,
        questionsToCreate,
        setProgress,
        doCreateQuestion,
        doUpdateQuestion
    ]);

    const {readString} = usePapaParse();
    const {questions: existingQuestions, error: getQuestionsError} = useQuestions(locale);
    const onUploadAccepted = (results: CsvData) => {
        setCsvData(results);
    };

    useEffect(() => {
        if (csvData && !error) {
            const data: string[][] = csvData.data;
            try {
                const questions = transformCSVDataToQuestions(data);
                const locale = questions[0]['locale'];
                setLocale(locale);
                setImportedQuestions(questions);
            } catch (error) {
                setCsvData(null);
                setError(
                    error instanceof Error ? error.message : 'An unexpected error occurred.'
                );
            }
        }
    }, [csvData, setError, error]);

    useEffect(() => {
        if (csvData && existingQuestions) {
            const questionsToUpdate = [];
            const questionsToCreate = [];
            for (const importedQuestion of importedQuestions) {
                const existingQuestion =
                    existingQuestions.find(
                        item =>
                            item.globalId === importedQuestion.globalId &&
                            item.locale === importedQuestion.locale &&
                            item.status !== 'RETIRED'
                    ) || null;
                if (existingQuestion?.title === importedQuestion.translatedTitle) {
                    continue;
                } else if (existingQuestion) {
                    questionsToUpdate.push({
                        ...importedQuestion,
                        oldTranslation: existingQuestion.title,
                        id: existingQuestion.id,
                        status: existingQuestion.status
                    });
                } else if (importedQuestion.globalId) {
                    questionsToCreate.push({
                        ...importedQuestion,
                        oldTranslation: '',
                        id: importedQuestion.globalId + importedQuestion.locale
                    });
                } else {
                    console.warn('Broken question:', importedQuestion);
                }
            }
            setQuestionsToCreate(questionsToCreate);
            setQuestionsToUpdate(questionsToUpdate);
        }
    }, [existingQuestions, importedQuestions, csvData, readString]);

    useEffect(() => {
        setError(error || getQuestionsError || updateQuestionError || createQuestionError);
    }, [error, getQuestionsError, updateQuestionError, createQuestionError]);

    const toolTip =
        'The following csv columns are expected: ' +
        Object.keys(importQuestionCSVHeaderkeyMap).join(', ');

    return {
        onUploadAccepted,
        questionsToCreate,
        questionsToUpdate,
        doImportQuestions,
        progress,
        error,
        toolTip
    };
};
