import {DataGrid, GridSortDirection} from '@mui/x-data-grid';
import {useCallback} from 'react';
import styled from 'styled-components';

import {useComparisonJobsLogic} from './hooks/useComparisonJobsLogic';
import {useTableLogic} from './hooks/useTableLogic';

interface RecentComparisonJobsProps {
    openUpdateParametersDialog: (comparisonJobId: any) => void;
    updatingParametersForComparisonJob: string | null;
    stopComparisonJob: (onJobStopped: () => void) => void;
}

export function RecentComparisonJobs(props: RecentComparisonJobsProps) {
    const {
        loading,
        error,
        parameterComparisonJobs,
        refetch: refetchComparisonJobs
    } = useComparisonJobsLogic();
    const stopComparisonJob = useCallback(() => {
        props.stopComparisonJob(refetchComparisonJobs);
    }, [props, refetchComparisonJobs]);
    const {columns, rows, handleSelection} = useTableLogic({
        openUpdateParametersDialog: props.openUpdateParametersDialog,
        updatingParametersForComparisonJob: props.updatingParametersForComparisonJob,
        stopComparisonJob: stopComparisonJob
    });

    return (
        <div>
            <h2>Last 10 comparison jobs</h2>
            {error && <h3>{error}</h3>}
            <br />
            <FlexBox>
                <DataGrid
                    rows={rows(parameterComparisonJobs)}
                    columns={columns}
                    sortModel={[{field: 'createdAt', sort: 'desc' as GridSortDirection}]}
                    onRowSelectionModelChange={handleSelection}
                    initialState={{
                        pagination: {paginationModel: {pageSize: 10}}
                    }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    autoHeight
                    loading={loading}
                />
            </FlexBox>
        </div>
    );
}

const FlexBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    max-width: 1560px;
    margin: 0 auto 40px auto;
`;
