import {useCallback, useState, SyntheticEvent} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, {AlertProps} from '@material-ui/lab/Alert';
import {makeStyles, Theme} from '@material-ui/core/styles';
import ReactDOM from "react-dom";

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export enum TOAST_TYPES {
    SUCCESS = "success",
    WARNING = "warning",
    ERROR = "error",
    INFO = "info"
}

interface ToastProps {
    type: TOAST_TYPES;
    message: string;
}

export function Toast({type = TOAST_TYPES.SUCCESS, message = 'Success!'}: ToastProps) {
    const classes = useStyles();
    const [open, setOpen] = useState(true);

    const handleClose = useCallback((event?: SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    }, []);

    const domNode = document.getElementById('root');
    if (!domNode) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className={classes.root}>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={type}>
                    {message}
                </Alert>
            </Snackbar>
        </div>,
        domNode
    );
}
