import {LocalizationProvider, DatePicker} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3';

import {CreateRecalculationJob} from './components/CreateRecalculationJob';
import {useDatesLogic} from "./hooks/useDatesLogic";
import {useHaActiveJobLogic} from "./hooks/useHasActiveJobLogic";
import {Header, Warning, DatePickerWrapper, StartJobWrapper} from './styled';

export function NewRecalculationJob() {
    const {
        fromDate, toDate, recalculatedBeforeDate,
        handleFromDateChange, handleToDateChange, handleRecalculatedBeforeDateChange
    } = useDatesLogic();
    const hasActiveJob = useHaActiveJobLogic();
    console.log(fromDate, toDate, recalculatedBeforeDate);
    return (
        <div>
            <Header>
                <h1>Create a new recalculation job!</h1>
                {hasActiveJob && (
                    <Warning>
                        Note: A recalculation job is currently running, and you won't be able to create a new one until
                        it's completed.
                    </Warning>
                )}
            </Header>
            <DatePickerWrapper>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <div>
                        <DatePicker
                            format="yyyy-MM-dd"
                            label="From"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            maxDate={toDate || undefined}
                        />
                        <DatePicker
                            format="yyyy-MM-dd"
                            label="To"
                            value={toDate}
                            onChange={handleToDateChange}
                            minDate={fromDate || undefined}
                            maxDate={new Date()}
                        />
                    </div>
                    <div>
                        <DatePicker
                            format="yyyy-MM-dd hh:mm:ss"
                            label="Ignore if recalculated after"
                            value={recalculatedBeforeDate}
                            onChange={handleRecalculatedBeforeDateChange}
                            minDate={fromDate || undefined}
                        />
                    </div>
                </LocalizationProvider>
            </DatePickerWrapper>
            <StartJobWrapper>
                <CreateRecalculationJob
                    fromDate={fromDate}
                    toDate={toDate}
                    recalculatedBeforeDate={recalculatedBeforeDate}
                    isDisabled={hasActiveJob}
                />
            </StartJobWrapper>
        </div>
    );
}

