const LocalConfig = {
    GOOGLE_CLIENT_ID: '719394616906-soir2qihj5g8orp8i7k4nfhuq7am7ffg.apps.googleusercontent.com',
    BASE_URL: 'http://localhost:3003',
    ALVA_APP_BASE_URL: 'http://localhost:3000'
};

const ProductionConfig = {
    GOOGLE_CLIENT_ID: '1094042559687-n8blc77f6j00of0e1ppf7vue8ta0kv46.apps.googleusercontent.com',
    BASE_URL: 'https://personality-test-management.alvalabs.io',
    ALVA_APP_BASE_URL: 'https://app.alvalabs.io'
};

const StagingConfig = {
    GOOGLE_CLIENT_ID: '1018564576558-76v2mshh9ck1gp5jrilh3lbfm6o2m0sd.apps.googleusercontent.com',
    BASE_URL: 'https://personality-test-management-staging.alvalabs.io',
    ALVA_APP_BASE_URL: 'https://staging.app.alvalabs.io'
};

function isLocal() {
    return window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
}

function isStaging() {
    return window.location.hostname.includes('personality-test-management-staging.alvalabs.io');
}

export function getConfig() {
    if (isLocal()) {
        return LocalConfig;
    }
    if (isStaging()) {
        return StagingConfig;
    }

    return ProductionConfig;
}

export function parseUrl(endpoint: string): string {
    // safari doesn't support regex lookbehinds, therefore not using those
    return `${endpoint}`.replace(/([^:]\/)\/+/g, "$1");
}
