import {useRecalculationJobSummary} from "../../../../../../../api/useRecalculationJobSummary";
import {useEffect, useMemo} from "react";
import {RecalculationJob} from "../../../../../../../api/types/RecalculationJob";
import {useUpdateActiveRecalculationJobStatus} from "../../../../../../../api/useUpdateActiveRecalculationJobStatus";

export function useRecalculationJobsLogic() {
    const {
        recalculationJobSummary,
        loading: loadingRecalculationJobSummary,
        error: recalculationJobSummaryError,
        refetch: refetchRecalculationJobSummary
    } = useRecalculationJobSummary();
    const [
        updateActiveRecalculationJobStatus,
        {success: successfullyUpdatedRecalculationJobStatus}
    ] = useUpdateActiveRecalculationJobStatus();

    const activeJobIsRunning = useMemo(() => {
        if (!recalculationJobSummary || recalculationJobSummary.jobs.length === 0) {
            return false;
        }

        return recalculationJobSummary.jobs.some((job: RecalculationJob) => job.status === 'ACTIVE');
    }, [recalculationJobSummary]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (activeJobIsRunning) {
                updateActiveRecalculationJobStatus().then(() => console.log('Updated active job status'));
            }
        }, 20000);
        return () => clearInterval(timer);
    });

    useEffect(() => {
        if (successfullyUpdatedRecalculationJobStatus) {
            refetchRecalculationJobSummary();
        }
    }, [successfullyUpdatedRecalculationJobStatus, refetchRecalculationJobSummary]);

    const getActiveJobProgress = () => {
        if (!recalculationJobSummary || !activeJobIsRunning) {
            return 'N/A';
        }

        const totalTests = recalculationJobSummary.jobs
            .find((job: RecalculationJob) => job.status === 'ACTIVE')!
            .actualNumberOfTestsToStart;
        const recalculatedTests = recalculationJobSummary!.activeJobRecalculatedTests;

        if (totalTests === 0 || recalculatedTests === 0) {
            return '0%';
        }

        return `${(recalculatedTests * 100 / totalTests).toFixed(1)}%`;
    }

    const recalculationJobs = useMemo(() => {
        return recalculationJobSummary && Array.isArray(recalculationJobSummary.jobs) ?
            recalculationJobSummary.jobs : [];
    }, [recalculationJobSummary]);

    return {
        recalculationJobs,
        activeJobIsRunning,
        getActiveJobProgress,
        loadingRecalculationJobSummary,
        recalculationJobSummaryError,
        refetchRecalculationJobSummary
    }
}
