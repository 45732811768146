import {useMemo} from "react";
import {useApiCall} from "./useApiCall";
import {parseLocales} from "./parse";
import {Locale} from "./types/Locale";

interface UseLocalesReturn {
    locales: Locale[];
    loading: boolean;
    error: string | null;
    refetch: () => void;
}

export function useLocales(): UseLocalesReturn {
    const url = `/api/locales.list`;
    const {data, error, loading, refetch} = useApiCall(url);

    const locales = useMemo(() => {
        if (data) {
            return parseLocales(data);
        }
        return [];
    }, [data]);

    return {
        locales,
        loading,
        error,
        refetch
    };
}
