import './spinner.css';

interface ISpinner {
    noMargin?: boolean,
    animated?: boolean
}

export const Spinner = ({noMargin = false, animated = false}: ISpinner) => {
    let extraClasses = [];

    if (noMargin) {
        extraClasses.push('no-margin');
    }

    if (animated) {
        extraClasses.push('animated');
        extraClasses.push('fadeIn');
    }

    return <div className={`spinner ${extraClasses.join(' ')}`}/>;
};

export default ISpinner;
