import {Fragment} from "react";
import {useLocalesLogic} from "./logic";
import Table from "@material-ui/core/Table";
import {Paper, Switch, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {getConfig} from "../../../../config";
import {Spinner} from "../../../../components/Spinner";
import {Toast, TOAST_TYPES} from "../../../../components/Toast";
import styled from "styled-components";
import { DataCollectionModeSelect } from "./components/DataCollectionModeSelect";


const DATA_COLLECTION_PATH = 'personality-test-data-collection';
const DATA_COLLECTION_SOURCES = {
    GENERAL: {name: 'General', value: null},
    PROLIFIC: {name: 'Prolific', value: 'prolific'},
    FIGURE_EIGHT: {name: 'Figure 8', value: 'figure8'}
};

export function Locales() {
    const {
        locales,
        loadingLocales,
        localeError,
        clearDataCollectionMode,
        updateDataCollectionStatus,
        updateDataCollectionMode,
        updateDataCollectionStatusError,
        updateDataCollectionModeError
    } = useLocalesLogic();

    if (loadingLocales) {
        return <Centered><Spinner/></Centered>;
    }

    if (localeError) {
        return <h3>Failed to fetch locales, please try refreshing the page.</h3>;
    }

    function renderDataCollectionLink(localeName: string, source: { name: string, value: string | null }) {
        const url = getDataCollectionUrl(localeName, source.value);

        return (
            <LinkWrapper>
                <b>{source.name}: </b>
                <Link
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {url}
                </Link>
            </LinkWrapper>
        );
    }

    function getDataCollectionUrl(localeName: string, source: string | null): string {
        const config = getConfig();
        const baseUrl = `${config.ALVA_APP_BASE_URL}/${DATA_COLLECTION_PATH}/${localeName.toLowerCase()}`;
        var queryString = source ? `source=${source}` : '';

        if(source === DATA_COLLECTION_SOURCES.PROLIFIC.value){
            queryString += "&PROLIFIC_PID={{%PROLIFIC_PID%}}&SESSION_ID={{%SESSION_ID%}}&STUDY_ID={{%STUDY_ID%}}";
        }

        if (!queryString) {
            return baseUrl;
        }

        return `${baseUrl}?${queryString}`;
    }

    return (
        <Fragment>
            <Wrapper>
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><b>Name</b></TableCell>
                                <TableCell><b>Is data collection enabled?</b></TableCell>
                                <TableCell><b>Data collection mode</b></TableCell>
                                <TableCell><b>Link to data collection</b></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {locales.map(locale => (
                                <TableRow key={locale.id}>
                                    <TableCell component="th" scope="row">{locale.name}</TableCell>
                                    <TableCell>
                                        <Switch
                                            checked={locale.isDataCollectionEnabled}
                                            onChange={(_, checked) => updateDataCollectionStatus(locale.id, checked)}
                                            color={"primary"}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <DataCollectionModeSelect 
                                            locale={locale} 
                                            updateDataCollectionMode={updateDataCollectionMode} 
                                            clearDataCollectionMode={clearDataCollectionMode}
                                        />
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {locale.isDataCollectionEnabled && (
                                            <LinksWrapper>
                                                {renderDataCollectionLink(locale.name, DATA_COLLECTION_SOURCES.GENERAL)}
                                                {renderDataCollectionLink(locale.name, DATA_COLLECTION_SOURCES.PROLIFIC)}
                                                {renderDataCollectionLink(locale.name, DATA_COLLECTION_SOURCES.FIGURE_EIGHT)}
                                            </LinksWrapper>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Wrapper>
            {updateDataCollectionStatusError && (
                <Toast type={TOAST_TYPES.ERROR} message={updateDataCollectionStatusError}/>
            )}
            {updateDataCollectionModeError && (
                <Toast type={TOAST_TYPES.ERROR} message={updateDataCollectionModeError}/>
            )}
        </Fragment>
    );
}

const Centered = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Link = styled.a`
  :hover {
    text-decoration: underline;
  }
  padding: 4px;
`;
