import {useCallback, useMemo} from 'react';

import {useApiOperation} from './useApiOperation';

type StopComparisonJobStatus = {
    ok: boolean;
    loading: boolean;
    error: string | null;
};

type StopComparisonJobReturn = [() => Promise<unknown>, StopComparisonJobStatus];

export const useStopComparisonJob = (): StopComparisonJobReturn => {
    const url = '/api/parameterComparisonJobs.stop';
    const [request, {data, error, loading}] = useApiOperation(url);

    const ok = useMemo(() => {
        return data && data.ok ? true : false;
    }, [data]);

    const makeRequest = useCallback(() => {
        return request({});
    }, [request]);

    return [
        makeRequest,
        {
            ok,
            loading,
            error
        } as StopComparisonJobStatus
    ];
};
