import {Fragment, useMemo} from "react";
import {Link} from "react-router-dom";
import {LINKS} from "../../../../links";
import {useRecalculationJobsLogic} from "./hooks/useRecalculationJobsLogic";
import {DataGrid, GridSortDirection} from "@mui/x-data-grid";
import {useTableLogic} from "./hooks/useTableLogic";
import {Toast, TOAST_TYPES} from "../../../../../../components/Toast";
import {useStopJobLogic} from "./hooks/useStopJobLogic";

export function RecalculationJobs() {
    const {
        recalculationJobs,
        activeJobIsRunning,
        getActiveJobProgress,
        loadingRecalculationJobSummary,
        recalculationJobSummaryError,
        refetchRecalculationJobSummary
    } = useRecalculationJobsLogic();
    const {stopJobError, successfullyStoppedJob, renderStopJobCell} = useStopJobLogic(refetchRecalculationJobSummary);
    const {columns, getRows} = useTableLogic(renderStopJobCell);

    const newRecalculationJobUrl = useMemo(() => {
        const baseUrl = LINKS.newRecalculationJob();
        return `${baseUrl}?hasActiveJob=${activeJobIsRunning}`;
    }, [activeJobIsRunning]);

    return (
        <div>
            <h2>List of recalculation jobs!</h2>
            <div>
                <Link to={newRecalculationJobUrl}>
                    Create new job
                </Link>
            </div>
            {recalculationJobSummaryError && (
                <h3>Failed to load recalculation jobs ({recalculationJobSummaryError}). Try refreshing the page.</h3>
            )}
            <Fragment>
                <p>
                    {loadingRecalculationJobSummary ? 'Loading...' : (
                        <Fragment>
                            Active job progress: <b>{getActiveJobProgress()}</b>
                        </Fragment>
                    )}
                </p>
                <DataGrid
                    rows={getRows(recalculationJobs)}
                    columns={columns}
                    sortModel={[{field: 'createdAt', sort: 'desc' as GridSortDirection}]}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    autoHeight
                    disableRowSelectionOnClick={true}
                    loading={loadingRecalculationJobSummary}
                />
            </Fragment>
            {stopJobError && <Toast type={TOAST_TYPES.ERROR} message={stopJobError}/>}
            {successfullyStoppedJob && <Toast type={TOAST_TYPES.SUCCESS} message={'Successfully stopped job!'}/>}
        </div>
    );
}
