import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';
import {Button, Stack} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {DataGrid, GridColDef} from '@mui/x-data-grid';

import styled from 'styled-components';

import CSVReader from '../../../../../../components/CSVReader';
import {LinearProgressWithLabel} from '../../../../../../components/LinearProgressBar';
import {TOAST_TYPES, Toast} from '../../../../../../components/Toast';
import {useImportQuestionLogic} from './logic';

export const ImportQuestions = () => {
    const {
        onUploadAccepted,
        questionsToCreate,
        questionsToUpdate,
        doImportQuestions,
        progress,
        error,
        toolTip
    } = useImportQuestionLogic();

    const columns: GridColDef[] = [
        {
            field: 'globalId',
            headerName: 'Global Id',
            editable: false,
            flex: 1
        },
        {
            field: 'locale',
            headerName: 'Locale',
            type: 'string',
            editable: false
        },
        {
            field: 'englishTitle',
            headerName: 'English Title',
            type: 'string',
            editable: false,
            flex: 2
        },
        {
            field: 'oldTranslation',
            headerName: 'Old translation',
            type: 'string',
            editable: true,
            align: 'left',
            headerAlign: 'left',
            flex: 2
        },

        {
            field: 'translatedTitle',
            headerName: 'New translation',
            type: 'string',
            editable: false,
            flex: 2
        }
    ];

    return (
        <div>
            <Header>
                <h1>
                    Import questions from csv
                    <Tooltip title={toolTip}>
                        <IconButton>
                            <HelpIcon />
                        </IconButton>
                    </Tooltip>
                </h1>
            </Header>

            <Wrapper>
                <ImportCSVWrapper>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <CSVReader onUploadAccepted={onUploadAccepted}></CSVReader>
                        <ButtonWrapper>
                            <Button
                                disabled={
                                    !(questionsToCreate.length || questionsToUpdate.length)
                                }
                                variant="contained"
                                onClick={doImportQuestions}
                            >
                                Import questions
                            </Button>
                        </ButtonWrapper>
                    </Stack>
                </ImportCSVWrapper>
                {questionsToUpdate.length ? (
                    <Accordion>
                        <AccordionSummary
                            sx={{flexDirection: 'row-reverse'}}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="updated-questions-header"
                        >
                            <h3>Questions that will be updated:</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DataGrid
                                rows={questionsToUpdate!}
                                columns={columns}
                                initialState={{
                                    pagination: {paginationModel: {pageSize: 10}}
                                }}
                                pageSizeOptions={[10, 20, 50, 100]}
                            />
                        </AccordionDetails>
                    </Accordion>
                ) : null}
                {questionsToCreate.length ? (
                    <Accordion>
                        <AccordionSummary
                            sx={{flexDirection: 'row-reverse'}}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="created-questions-header"
                        >
                            <h3>Questions that will be created:</h3>
                        </AccordionSummary>
                        <AccordionDetails>
                            <DataGrid
                                rows={questionsToCreate!}
                                columns={columns}
                                initialState={{
                                    pagination: {paginationModel: {pageSize: 10}}
                                }}
                                pageSizeOptions={[10, 20, 50, 100]}
                            />
                        </AccordionDetails>
                    </Accordion>
                ) : null}

                {progress != null ? <LinearProgressWithLabel value={progress} /> : null}
                {progress === 100 && (
                    <Toast
                        type={TOAST_TYPES.SUCCESS}
                        message={'Successfully imported questions from csv'}
                    />
                )}
                {error && <Toast type={TOAST_TYPES.ERROR} message={error} />}
            </Wrapper>
        </div>
    );
};

const Header = styled.div`
    max-width: 480px;
    margin: 0 auto;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
const ImportCSVWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ButtonWrapper = styled.div`
    max-height: 60px;
`;
