import {InputLabel, Paper} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import {Button, ButtonProps} from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import {
    DataGrid,
    GridColDef,
    GridCsvExportMenuItem,
    GridRowParams,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarContainerProps,
    GridToolbarDensitySelector,
    GridToolbarExportContainer,
    GridToolbarFilterButton,
    useGridApiContext
} from '@mui/x-data-grid';
import {Fragment} from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

import {QuestionStatus} from '../../../../../../api/types/QuestionStatus';
import {Spinner} from '../../../../../../components/Spinner';
import {LINKS} from '../../../../links';
import CreateQuestionModal from './components/CreateQuestionModal';
import {LocaleAndQuestionGlobalIdFilter} from './components/LocaleAndQuestionGlobalIdFilter/LocaleAndQuestionGlobalIdFilter';
import {useQuestionsLogic} from './logic';

export const QuestionOverview = () => {
    const {
        questionsWithEnglishTitle,
        error,
        locale,
        status,
        selectStatus,
        selectLocale,
        clearStatus,
        clearLocale,
        updatedRows,
        processRowUpdate,
        onProcessRowUpdateError,
        updateSnackbar,
        createSnackbar,
        handleCloseUpdateSnackbar,
        handleCloseCreateSnackbar,
        commitRow,
        openCreateQuestionModal,
        handleOpenCreateQuestionModal,
        handleCloseCreateQuestionModal,
        handleSubmitCreateQuestion
    } = useQuestionsLogic();

    if (error) {
        return <h3>Failed to load questions: {error}. Try refreshing the page.</h3>;
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'Id',
            editable: false,
            flex: 1
        },
        {
            field: 'globalId',
            headerName: 'Global Id',
            editable: false,
            flex: 1
        },
        {
            field: 'title',
            headerName: 'Title',
            type: 'string',
            editable: true,
            align: 'left',
            headerAlign: 'left',
            flex: 4
        },
        {
            field: 'englishTitle',
            headerName: 'English Title',
            type: 'string',
            editable: false,
            flex: 4
        },
        {
            field: 'status',
            headerName: 'Status',
            type: 'singleSelect',
            valueOptions: Object.values(QuestionStatus),
            editable: true,
            flex: 1
        },
        {
            field: 'locale',
            headerName: 'Locale',
            type: 'string',
            editable: false
        },
        {
            field: 'translatedTitle',
            headerName: 'Translated Title',
            type: 'string',
            editable: false
        },
        {
            field: 'save',
            headerName: 'Save',
            sortable: false,
            renderCell: ({row}: Partial<GridRowParams>) => {
                const updated = row.id in updatedRows;
                return (
                    <Button
                        variant="contained"
                        size="small"
                        disabled={!updated}
                        onClick={() => commitRow(row)}
                    >
                        Commit
                    </Button>
                );
            }
        }
    ];

    return (
        <Fragment>
            <Wrapper>
                <Paper>
                    <h2>Filters</h2>
                    <SelectWrapper>
                        <Label id="select-label">Choose a filter to see results.</Label>
                        <LocaleAndQuestionGlobalIdFilter
                            questions={questionsWithEnglishTitle}
                            status={status}
                            locale={locale}
                            selectStatus={selectStatus}
                            selectLocale={selectLocale}
                            clearStatus={clearStatus}
                            clearLocale={clearLocale}
                        />
                    </SelectWrapper>
                    <QuestionActionWrapper>
                        <Link to={LINKS.importQuestions()}>Import questions from CSV</Link>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleOpenCreateQuestionModal}
                        >
                            Create question
                        </Button>
                        <CreateQuestionModal
                            open={openCreateQuestionModal}
                            onClose={handleCloseCreateQuestionModal}
                            onSubmit={handleSubmitCreateQuestion}
                        />
                    </QuestionActionWrapper>
                    {questionsWithEnglishTitle == null ? (
                        <Centered>
                            <Spinner />
                        </Centered>
                    ) : (
                        <DataGrid
                            rows={questionsWithEnglishTitle!}
                            processRowUpdate={processRowUpdate}
                            onProcessRowUpdateError={onProcessRowUpdateError}
                            columnVisibilityModel={{
                                locale: false,
                                translatedTitle: false
                            }}
                            slots={{toolbar: CustomToolbar}}
                            columns={columns}
                        />
                    )}
                    {!!updateSnackbar && (
                        <Snackbar
                            open
                            onClose={handleCloseUpdateSnackbar}
                            autoHideDuration={6000}
                        >
                            <Alert {...updateSnackbar} onClose={handleCloseUpdateSnackbar} />
                        </Snackbar>
                    )}
                    {!!createSnackbar && (
                        <Snackbar
                            open
                            onClose={handleCloseCreateSnackbar}
                            autoHideDuration={6000}
                        >
                            <Alert {...createSnackbar} onClose={handleCloseCreateSnackbar} />
                        </Snackbar>
                    )}
                </Paper>
            </Wrapper>
        </Fragment>
    );
};

const CustomExportMenuItem = () => {
    const apiRef = useGridApiContext();

    return (
        <MenuItem
            onClick={() => {
                apiRef.current.exportDataAsCsv({
                    fields: ['globalId', 'locale', 'englishTitle', 'translatedTitle'],
                    delimiter: ';'
                });
            }}
        >
            Download as CSV for translation
        </MenuItem>
    );
};

const CustomExportButton = (props: ButtonProps) => {
    return (
        <GridToolbarExportContainer {...props}>
            <GridCsvExportMenuItem options={{delimiter: ';'}} />
            <CustomExportMenuItem />
        </GridToolbarExportContainer>
    );
};

const CustomToolbar = (props: GridToolbarContainerProps) => {
    return (
        <GridToolbarContainer {...props}>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <CustomExportButton />
        </GridToolbarContainer>
    );
};

const Centered = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Wrapper = styled.div`
    max-width: 1440px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
`;

const SelectWrapper = styled.div`
    margin-bottom: 20px;
`;

const QuestionActionWrapper = styled.div`
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    margin: 20px;
`;

const Label = styled(InputLabel)`
    margin-bottom: 12px;
`;
