import {useApiOperation} from "./useApiOperation";
import {useCallback} from "react";

interface UpdateActiveRecalculationJobStatus {
    success: boolean;
}

type Return = [() => Promise<unknown>, UpdateActiveRecalculationJobStatus];

export function useUpdateActiveRecalculationJobStatus(): Return {
    const url = '/api/recalculationJobs.updateActiveJob';
    const [request, {success}] = useApiOperation(url);

    const makeRequest = useCallback(() => {
        return request({});
    }, [request]);

    return [makeRequest, {success} as UpdateActiveRecalculationJobStatus];
}
