
import {DataGrid} from "@mui/x-data-grid";
import {useParameterComparisonResultsForJob} from "../../../../../../../../api/useParameterComparisonResultsForJob";
import {useTableLogic} from "./hooks/useTableLogic";
import {getDefaultSortingForParametersTable} from "../../../../utils/tableUtils";

interface Props {
    comparisonJobId: string;
    locale: string;
    facet: string;
}

export function ParameterComparisonResults({comparisonJobId, locale, facet}: Props) {
    const {
        comparisonResults,
        loading,
        error
    } = useParameterComparisonResultsForJob({comparisonJobId, locale, facet});
    const {columns, getRows} = useTableLogic();

    return (
        <div>
            <h2>Comparison job results</h2>
            {error && <h3>{error}</h3>}
            <br/>
            <DataGrid
                rows={getRows(comparisonResults)}
                columns={columns}
                initialState={{
                    pagination: { paginationModel: { pageSize: 20 } },
                }}
                pageSizeOptions={[10, 20, 50, 100]}
                autoHeight={true}
                loading={loading}
                sortModel={getDefaultSortingForParametersTable()}
                disableRowSelectionOnClick={true}
            />
        </div>
    )
}
