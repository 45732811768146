import {useCallback, useEffect, useState} from "react";
import {usePreviewRecalculationJob} from "../../../../../../../../../api/usePreviewRecalculationJob";
import {useCreateRecalculationJob} from "../../../../../../../../../api/useCreateRecalculationJob";

interface Arguments {
    fromDate: string | Date;
    toDate: string | Date;
    recalculatedBeforeDate: string | Date;
}

export function useCreateRecalculationJobLogic({fromDate, toDate, recalculatedBeforeDate}: Arguments) {
    const [showCreateJob, setShowCreateJob] = useState(false);
    const [doPreviewJob, {totalTests, loading: previewingJob}] = usePreviewRecalculationJob();
    const [doCreateJob, {recalculationJobId, loading: creatingJob}] = useCreateRecalculationJob();

    const previewJob = useCallback(() => {
        doPreviewJob(fromDate, toDate, recalculatedBeforeDate).then(() => {
            console.log('Preview job completed.');
        });
    }, [doPreviewJob, fromDate, toDate, recalculatedBeforeDate]);

    const createRecalculationJob = useCallback(() => {
        doCreateJob(fromDate, toDate, recalculatedBeforeDate).then(() => {
            console.log('Job successfully created');
        });
    }, [doCreateJob, fromDate, toDate, recalculatedBeforeDate]);

    useEffect(() => {
        setShowCreateJob(false);
    }, [fromDate, toDate, recalculatedBeforeDate]);

    useEffect(() => {
        if (totalTests !== null) {
            setShowCreateJob(true);
        } else {
            setShowCreateJob(false);
        }
    }, [totalTests]);

    return {
        showCreateJob,
        previewingJob,
        creatingJob,
        totalTests,
        recalculationJobId,
        previewJob,
        createRecalculationJob
    };
}
