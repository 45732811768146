import {useCallback, useEffect, useMemo} from 'react';

import {useLast10ParameterComparisonJobs} from '../../../../../../../api/useLast10ParameterComparisonJobs';

export function useComparisonJobsLogic() {
    const {parameterComparisonJobs, loading, error, refetch} =
        useLast10ParameterComparisonJobs();

    const activeJobIsRunning = useMemo(() => {
        const activeJob = parameterComparisonJobs.find(job => job.status === 'ACTIVE');
        return !!activeJob;
    }, [parameterComparisonJobs]);

    const refetchJobsIfActiveJobIsRunning = useCallback(() => {
        if (activeJobIsRunning) {
            refetch();
        }
    }, [activeJobIsRunning, refetch]);

    useEffect(() => {
        const timer = setInterval(refetchJobsIfActiveJobIsRunning, 20000);
        return () => clearInterval(timer);
    }, [refetchJobsIfActiveJobIsRunning]);

    return {parameterComparisonJobs, loading, error, refetch};
}
