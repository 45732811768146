import styled from "styled-components";

export function RequiredColumns() {
    return (
        <Wrapper>
            <h3>Columns in BigQuery table:</h3>
            <List>
                <ListItem><code>global_id</code></ListItem>
                <ListItem><code>locale</code></ListItem>
                <ListItem><code>status</code></ListItem>
                <ListItem><code>facet</code></ListItem>
                <ListItem><code>positive_key</code></ListItem>
                <ListItem><code>alpha</code></ListItem>
                <ListItem><code>kappa_1</code></ListItem>
                <ListItem><code>kappa_2</code></ListItem>
                <ListItem><code>kappa_3</code></ListItem>
                <ListItem><code>kappa_4</code></ListItem>
                <ListItem><code>alpha_sigma</code></ListItem>
                <ListItem><code>kappa_1_sigma</code></ListItem>
                <ListItem><code>kappa_2_sigma</code></ListItem>
                <ListItem><code>kappa_3_sigma</code></ListItem>
                <ListItem><code>kappa_4_sigma</code></ListItem>
            </List>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ListItem = styled.li`
  text-align: left;
  margin-bottom: 4px;
`;
