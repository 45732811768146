import {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {buildRequestHeaders, clearToken, getToken} from "../services/auth/helpers";
import {parseUrl} from "../config";
import {formatError} from "./utils";



interface RequestState {
    loading: boolean;
    error: string | null;
    data?: any;
}

interface ApiCallReturn {
    data?: any;
    loading: boolean;
    error: string | null;
    refetch: () => void
}

class NoOpApiCallReturn implements ApiCallReturn {
    loading = false;
    error = null;
    data = null;
    refetch = () => {}
}

export function useApiCall(url: string | null, skipRequest: boolean=false): ApiCallReturn {
    const [status, setStatus] = useState<RequestState>({loading: true, error: null, data: null});
    const history = useHistory();
    const authorizationToken = getToken();

    const makeApiCall = useCallback(() => {
        if (!authorizationToken) {
            return;
        }

        if (!url || skipRequest){
            setStatus(new NoOpApiCallReturn());
            return;
        }
        setStatus({loading: true, error: null, data: null});
        const options = {
            method :'GET',
            headers: buildRequestHeaders()
        };

        fetch(parseUrl(url), options).then(res => res.json()).then(response => {
            if (response.status === 403) {
                clearToken();
                history.push('/login');
            }
            else if (response.data) {
                setStatus({loading: false, error: null, data: response.data});
            }
            else {
                const e = formatError(response.error);
                setStatus({loading: false, error: e.toString(), data: null});
                throw new Error(e);
            }
        }).catch(error => {
            console.log(error);
        });
    }, [url, authorizationToken, history, skipRequest]);

    const refetch = useCallback(() => {
        makeApiCall();
    }, [makeApiCall]);

    useEffect(() => {
        makeApiCall();
    }, [makeApiCall]);

    return {
        loading: status.loading,
        error: status.error,
        data: status.data,
        refetch
    }
}
