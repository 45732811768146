export function formatDate(dateString: Date | string, withTime: boolean = false): string | null {
    if (!dateString) {
        return null;
    }

    try {
        const date = adjustTimezoneForDate(new Date(dateString));
        return withTime ? date.toISOString().split('.')[0].replace('T', ' ') : date.toISOString().split('T')[0];
    } catch {
        return null;
    }
}

export function secondsToDaysHoursMinutesSeconds(seconds: number): string {
    console.log(seconds);
    let d = Math.floor(seconds / (3600 * 24));
    let h = Math.floor(seconds % (3600 * 24) / 3600);
    let m = Math.floor(seconds % 3600 / 60);
    let s = Math.floor(seconds % 60);

    let dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
    let hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
    let mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
    let sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
}

function adjustTimezoneForDate(date: Date) {
    const offset = date.getTimezoneOffset();
    return new Date(date.getTime() - (offset * 60 * 1000));
}

export const roundValue = (value: number, decimals: number = 3) => {
    return Math.round(Math.pow(10, decimals) * value) / (Math.pow(10, decimals));
}
