import styled from "styled-components";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import {LINKS} from "./links";


export const Navbar = () => (
    <AppBar>
        <Toolbar>
            <InnerWrapper>
                <div>
                    <Button href={LINKS.parameters()} color={'inherit'}>
                        Parameters
                    </Button>
                    <Button href={LINKS.recalculation()} color={'inherit'}>
                        Recalculation
                    </Button>
                    <Button href={LINKS.locales()} color={'inherit'}>
                        Locales
                    </Button>
                     <Button href={LINKS.questions()} color={'inherit'}>
                        Questions
                    </Button>
                </div>
                <div>
                    <Button href={LINKS.logout()} color={'inherit'}>
                        Logout
                    </Button>
                </div>
            </InnerWrapper>
        </Toolbar>
    </AppBar>
);

const InnerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;
