import {DataGrid} from '@mui/x-data-grid';
import styled from "styled-components";
import {useCurrentParameters} from "../../../../../../api/useCurrentParameters";
import {useTableLogic} from "./hooks/useTableLogic";
import {InputLabel} from "@material-ui/core";
import {getDefaultSortingForParametersTable} from "../../utils/tableUtils";
import {useFilterLogic} from "../ComparisonJobResults/hooks/useFilterLogic";
import {LocaleAndFacetFilter} from "../LocaleAndFacetFilter";


export function CurrentParameters() {
    const {facet, locale, selectFacet, selectLocale, clearFacet, clearLocale} = useFilterLogic();
    const {loading, error, parameters} = useCurrentParameters(locale, facet);
    const {columns, getRows} = useTableLogic(parameters);

    return (
        <div>
            <h2>Current parameters</h2>
            <SelectWrapper>
                <Label id="select-label">Choose a filter to see results.</Label>
                    <LocaleAndFacetFilter
                        facet={facet}
                        locale={locale}
                        selectFacet={selectFacet}
                        selectLocale={selectLocale}
                        clearFacet={clearFacet}
                        clearLocale={clearLocale}
                    />
            </SelectWrapper>
            {error ? (
                <h2>Failed to load current parameters, try refreshing the page.</h2>
            ) : (
                <DataGrid
                    rows={getRows()}
                    columns={columns}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 20 } },
                    }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    autoHeight={true}
                    loading={loading}
                    sortModel={getDefaultSortingForParametersTable()}
                    disableRowSelectionOnClick={true}
                />
            )}
        </div>
    )
}

const SelectWrapper = styled.div`
  margin-bottom: 20px;
`;

const Label = styled(InputLabel)`
  margin-bottom: 12px;
`;
