import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@material-ui/core";

interface Props {
    isOpen: boolean;
    close: () => void;
    createJob: () => void;
    updatingParameters: boolean;
}


export const UpdateParametersDialog = ({isOpen, close, createJob, updatingParameters}: Props) => (
    <Dialog
        open={isOpen}
        onClose={close}
    >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
            <DialogContentText>Parameters will be uploaded and used for all new tests.</DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={close} color='secondary'>
                No
            </Button>
            <Button onClick={createJob} color='primary' disabled={updatingParameters}>
                {updatingParameters ? <CircularProgress size={23.5}/> : 'Yes'}
            </Button>
        </DialogActions>
    </Dialog>
);
