import {useMemo} from "react";
import {useApiCall} from "./useApiCall";

interface UseBigQueryDatasetUrlReturn {
    url: string;
    loading: boolean;
    error: string | null;
    refetch: () => void;
}

export function useBigQueryDatasetUrl(): UseBigQueryDatasetUrlReturn {
    const apiUrl = '/api/parameters.getBigQueryDatasetUrl';
    const {loading, error, data, refetch} = useApiCall(apiUrl);

    const url = useMemo(() => {
        return data ? data.url : null;
    }, [data]);

    return {
        url,
        loading,
        error,
        refetch
    }
}
