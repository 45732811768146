import {useCallback} from 'react';

import {useApiOperation} from './useApiOperation';

type UpdateQuestions = {
    loading: boolean;
    success: boolean;
    error: string | null;
};

type UpdateQuestionReturn = [
    (id: string, title: string, status: string) => Promise<unknown>,
    UpdateQuestions
];

export const useUpdateQuestion = (): UpdateQuestionReturn => {
    const baseUrl = `/api/questions.update`;
    const [request, {error, loading, success}] = useApiOperation(baseUrl, {method: 'PUT'});

    const makeRequest = useCallback(
        (id: string, title: string, status: string) => {
            const payload = {title, status};
            return request(payload, id);
        },
        [request]
    );

    return [
        makeRequest,
        {
            loading,
            success,
            error
        } as UpdateQuestions
    ];
};
