import {useLocales} from "../../../../api/useLocales";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useUpdateDataCollectionStatusForLocale, useUpdateDataCollectionModeForLocale} from "../../../../api/useUpdateDataCollectionStatusForLocale";
import {Locale} from "../../../../api/types/Locale";
import { DataCollectionMode } from "./constants";

export function useLocalesLogic() {
    const {locales: allLocales, loading: loadingLocales, error: localeError} = useLocales();
    const [locales, setLocales] = useState<Locale[]>([]);

    useEffect(() => {
        if (!loadingLocales && !localeError) {
            setLocales(allLocales);
        }
    }, [loadingLocales, localeError, allLocales]);

    const [doUpdateDataCollectionStatus, {
        loading: updatingDataCollectionStatus,
        error: updateDataCollectionStatusError
    }] = useUpdateDataCollectionStatusForLocale();

    const [doUpdateDataCollectionMode, {
        loading: updatingDataCollectionMode,
        error: updateDataCollectionModeError
    }] = useUpdateDataCollectionModeForLocale();

    const updateLocalesStatus = useCallback((localeId: string) => {
        const updatedLocales = locales.map(locale => {
            if (locale.id === localeId) {
                return Object.assign({}, locale, {
                    isDataCollectionEnabled: !locale.isDataCollectionEnabled
                });
            }

            return locale;
        });

        setLocales(updatedLocales);
    }, [locales]);

    const updateLocalesMode = useCallback((localeId: string, mode?: DataCollectionMode) => {
        const updatedLocales = locales.map(locale => {
            if (locale.id === localeId) {
                return Object.assign({}, locale, {
                    dataCollectionMode: mode
                });
            }

            return locale;
        });

        setLocales(updatedLocales);
    }, [locales]);

    const clearDataCollectionMode = useCallback((localeId: string) => {
        if (updatingDataCollectionMode) {
            return;
        }

        doUpdateDataCollectionMode(localeId, undefined)
            .then(() => updateLocalesMode(localeId, undefined))
            .catch((e) => console.log(e));
    }, [doUpdateDataCollectionMode, updatingDataCollectionMode, updateLocalesMode]);

    const updateDataCollectionStatus = useCallback((localeId: string, isDataCollectionEnabled: boolean) => {
        if (updatingDataCollectionStatus) {
            return;
        }

        doUpdateDataCollectionStatus(localeId, isDataCollectionEnabled)
            .then(() => updateLocalesStatus(localeId))
            .catch((e) => console.log(e));
    }, [doUpdateDataCollectionStatus, updatingDataCollectionStatus, updateLocalesStatus]);

    const updateDataCollectionMode = useCallback((localeId: string, dataCollectionMode: DataCollectionMode) => {
        if (updatingDataCollectionMode) {
            return;
        }

        doUpdateDataCollectionMode(localeId, dataCollectionMode)
            .then(() => updateLocalesMode(localeId, dataCollectionMode))
            .catch((e) => console.log(e));
    }, [doUpdateDataCollectionMode, updatingDataCollectionMode, updateLocalesMode]);


    const sortedLocales = useMemo(() => {
        return locales.sort((a, b) => a.name.localeCompare(b.name));
    }, [locales]);

    return {
        locales: sortedLocales,
        loadingLocales,
        localeError,
        updateDataCollectionStatus,
        updateDataCollectionMode,
        updateDataCollectionStatusError,
        updateDataCollectionModeError,
        clearDataCollectionMode
    }
}
