import MenuItem from '@material-ui/core/MenuItem';
import {Question} from 'api/types/Question';
import {useLocales} from '@/api/useLocales';
import {Filter} from '@/components/Filter';
import styled from 'styled-components';

interface Props {
    questions: Question[] | null;
    status: string | null;
    locale: string;
    selectStatus: (e: any) => void;
    selectLocale: (e: any) => void;
    clearStatus: () => void;
    clearLocale: () => void;
}

export function LocaleAndQuestionGlobalIdFilter({
    questions = [],
    status,
    locale,
    selectStatus,
    selectLocale,
    clearStatus,
    clearLocale
}: Props) {
    const {locales, loading} = useLocales();

    if (loading) {
        return null;
    }

    return (
        <FilterBox>
            <Filter
                filterId={'select-locale'}
                label={'Locale: '}
                onChange={selectLocale}
                onClear={clearLocale}
                selectedValue={locale}
            >
                {locales.map(entry => (
                    <MenuItem key={`locale-option-${entry.name}`} value={entry.name}>
                        {entry.name}
                    </MenuItem>
                ))}
            </Filter>

            {/* ===== COMMENTED, WILL BE PLUGGED IN THE NEXT PR ===== <Filter
                filterId={'select-status'}
                label={'Status: '}
                onChange={selectStatus}
                onClear={clearStatus}
                selectedValue={status ||''}
            >
                {Object.values(QuestionStatus).map((questionStatus) => (
                    <MenuItem key={`status-option-${questionStatus.toString()}`} value={questionStatus.toString()}>{questionStatus.toString()}</MenuItem>
                ))}
            </Filter> */}
        </FilterBox>
    );
}

const FilterBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
`;
