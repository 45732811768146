import {useMemo} from "react";
import {useApiCall} from "./useApiCall";
import {parseComparisonJobs} from "./parse";
import {ParameterComparisonJob} from "./types/ParameterComparisonJob";

interface UseLast10ParameterComparisonJobsReturn {
    parameterComparisonJobs: ParameterComparisonJob[];
    loading: boolean;
    error: string | null;
    refetch: () => void;
}

export function useLast10ParameterComparisonJobs(): UseLast10ParameterComparisonJobsReturn {
    const url = `/api/parameterComparisonJobs.listLast10`;
    const {data, error, loading, refetch} = useApiCall(url);

    const parameterComparisonJobs = useMemo(() => {
        if (data) {
            return parseComparisonJobs(data);
        }
        return [];
    }, [data]);

    return {parameterComparisonJobs, loading, error, refetch};
}
