import {Fragment, useCallback, useMemo} from "react";
import styled from "styled-components";
import {ParameterComparisonSummary} from "../../../../../../../../api/types/ParameterComparisonSummary";

export function useRenderSummaryTable(summary: ParameterComparisonSummary[], locales: string[], facets: string[]) {
    const buildCell = useCallback((rowKey: string, columnKey: string) => {
        const key = `${rowKey}_${columnKey}`;
        const inverseKey = `${columnKey}_${rowKey}`;
        const item = summary.find((item: ParameterComparisonSummary) => {
            const itemKey = `${item.locale}_${item.facet}`;
            return itemKey === key || itemKey === inverseKey;
        });
        return {
            questionsWithWarning: item ? item.questionsWithWarning : 'N/A',
            updatedQuestions: item ? item.updatedQuestions : 'N/A',
            activeQuestions: item ? item.activeQuestions : 'N/A'
        };
    }, [summary]);

    const deriveRows = useCallback(() => {
        if (facets.length >= 1 && locales.length === 1) {
            return locales;
        }

        return facets;
    }, [facets, locales]);

    const deriveColumns = useCallback(() => {
        if (facets.length >= 1 && locales.length === 1) {
            return facets;
        }

        return locales;
    }, [facets, locales]);

    return useMemo(() => {
        const rows = deriveRows();
        const columns = deriveColumns();

        return (
            <Fragment>
                <tr key={"first-row"}>
                    <Td key="corner-cell">{""}</Td>
                    {columns.map((columnKey: string) => (
                        <Td key={`first-row-${columnKey}`}>{columnKey}</Td>
                    ))}
                </tr>
                {rows.map((rowKey: string) => (
                    <tr key={`${rowKey}-row`}>
                        <Td key={`row-${rowKey}-first-cell`}>{rowKey}</Td>
                        {columns.map((columnKey: string) => {
                            const cell = buildCell(rowKey, columnKey);

                            return (
                                <RoundedTd key={`${rowKey}-${columnKey}-cell`}>
                                    <Warning>{cell.questionsWithWarning}</Warning>,&nbsp;
                                    <Updated>{cell.updatedQuestions}</Updated>,&nbsp;
                                    <Active>{cell.activeQuestions}</Active>
                                </RoundedTd>
                            );
                        })}
                    </tr>
                ))}
            </Fragment>
        );
    }, [deriveRows, deriveColumns, buildCell]);
}

const Td = styled.td`
  max-width: 110px;
`;

const RoundedTd = styled(Td)`
    padding: 6px;
    text-align: center;
    font-size: 12px;
    border-spacing: 10px;
    border: 1px solid #ccc;
    margin: 0 18px 18px 0;
    border-radius: 6px;
`;

const Warning = styled.span`
    color: red;
`;

const Updated = styled.span`
    color: blue;
`;

const Active = styled.span`
    color: green;
`;
