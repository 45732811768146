import {useCallback} from 'react';

import {useStopComparisonJob} from '../../../../../../../api/useStopComparisonJob';

export const useStopComparisonJobLogic = () => {
    const [
        doStopComparisonJob,
        {ok, loading: stoppingComparisonJob, error: stopComparisonJobError}
    ] = useStopComparisonJob();

    const stopComparisonJob = useCallback(
        (onJobStopped: () => void) => {
            doStopComparisonJob().then(() => onJobStopped());
        },
        [doStopComparisonJob]
    );

    return {
        stopComparisonJob,
        stoppingComparisonJob,
        successfullyStoppedComparisonJob: ok,
        stopComparisonJobError
    };
};
