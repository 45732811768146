import {Fragment} from "react";
import {GridCellParams, GridSortDirection} from "@mui/x-data-grid";
import {roundValue} from "../../../../../utils";

function getBooleanAsNumber(value: boolean | null): number {
    if (value === null) {
        return -1;
    }

    return Number(value);
}

export function booleanSortComparator(v1: any, v2: any): number {
    const number1 = getBooleanAsNumber(v1);
    const number2 = getBooleanAsNumber(v2);
    return number1 - number2;
}

export function getDefaultSortingForParametersTable() {
    return [{
        field: 'globalId',
        sort: 'asc' as GridSortDirection
    }];
}

export function globalIdSortComparator(v1: any, v2: any): number {
    return sortFunction(v1, v2);
}

function getQuestionNumber(globalId?: any): number | null {
    if (!globalId) {
        return -1;
    }

    const parts = globalId.split('_');
    if (parts.length < 2) {
        return null;
    }

    return parseInt(parts[1]);
}

function sortFunction(globalId1: any, globalId2: any): number {
    const questionNumber1 = getQuestionNumber(globalId1);
    const questionNumber2 = getQuestionNumber(globalId2);

    if (questionNumber1 === null || questionNumber2 === null) {
        return globalId1.localeCompare(globalId2);
    }

    return questionNumber1 - questionNumber2;
}

export const renderPositiveKey = (params: GridCellParams) => {
    let text = '';
    if (params.value !== null) {
        text = params.value ? 'True' : 'False';
    }

    return <Fragment>{text}</Fragment>
}

export const parameterValueFormatter = (value: any) => value === null ? null : roundValue(value);
