import { useState, useEffect } from 'react';

type SnackbarState = {
    children: string,
    severity: 'error' | 'success' | 'info' | 'warning'
};

export const useSnackbar = (
    errorCondition: string | null,
    successCondition: string | null
) => {
    const [snackbar, setSnackbar] = useState<SnackbarState | null>(null);

    const handleCloseSnackbar = () => setSnackbar(null);

    useEffect(() => {
        if (errorCondition) {
            setSnackbar({
                children: errorCondition,
                severity: 'error',
            });
        } else if (successCondition) {
            setSnackbar({
                children: successCondition,
                severity: 'success',
            });
        }
    }, [errorCondition, successCondition]);

    return {
        snackbar,
        handleCloseSnackbar
    };
};
