
import styled from "styled-components";
import {useParameterComparisonSummary} from "../../../../../../../../api/useParameterComparisonSummary";
import {FACETS, LOCALES} from "../../constants";
import {CircularProgress} from "@material-ui/core";
import {useRenderSummaryTable} from "./useRenderSummaryTable";

interface Props {
    comparisonJobId: string;
    locale: string;
    facet: string;
}

export function ParameterComparisonSummary({comparisonJobId, locale, facet}: Props) {
    const {parameterComparisonSummary, loading, error} = useParameterComparisonSummary({
        comparisonJobId,
        locale,
        facet
    });
    const locales = locale ? [locale] : Object.keys(LOCALES);
    const facets = facet ? [facet] : FACETS;
    const table = useRenderSummaryTable(parameterComparisonSummary, locales, facets);

    return (
        <div>
            <h2>Comparison job summary</h2>
            <Warning>Number of questions with warnings</Warning>
            <Updated>Number of updated questions</Updated>
            <Active>Number of active questions</Active>
            {error && <h3>{error}</h3>}
            <br/>
            <FlexBox>
                {loading ?
                    <Center>
                        <CircularProgress size={23.5}/>
                    </Center> :
                    <Table>
                        <tbody>
                            {table}
                        </tbody>
                    </Table>
                }
            </FlexBox>
        </div>
    )
}

const FlexBox = styled.div`
  display: flex;
  justify-content: center;
`;

const Table = styled.table`
  border-spacing: 10px;
  width: auto;  
  padding-bottom: 40px; 
`
const Warning = styled.p`
    color: red;
`;

const Updated = styled.p`
    color: blue;
`;

const Active = styled.p`
    color: green;
`;

const Center = styled.div`
      margin: auto;
      width: 50%;
      padding: 10px;
`;
