import { Chart } from "@/components/chart";
import {useParameterAgeMetricsLogic} from "./logic";

export function ParameterAgeMetrics() {
    const {
        xAxisValues,
        youngerThanOneMonth,
        youngerThanThreeMonths,
        youngerThanSixMonths,
        youngerThanTwelveMonths,
        olderThanTwelveMonths
    } = useParameterAgeMetricsLogic();

    const dataSource = {
        chart: {
            xAxisname: "Period (yyyy-mm)",
            yAxisName: "Number of tests",
            theme: "fusion"
        },
        categories: [{
            category: xAxisValues
        }],
        dataset: [{
            seriesName: "< 1 month",
            data: youngerThanOneMonth,
            color: "#2AA779"
        }, {
            seriesName: "< 3 months",
            data: youngerThanThreeMonths,
            color: "#BDEBCC"
        }, {
            seriesName: "< 6 months",
            data: youngerThanSixMonths,
            color: "#FFEFC5"
        }, {
            seriesName: "< 12 months",
            data: youngerThanTwelveMonths,
            color: "#F9D6CE"
        }, {
            seriesName: "> 12 months",
            data: olderThanTwelveMonths,
            color: "#C10D30"
        }]
    };

    return (
        <div>
            <h1>Visualization of parameter age</h1>
            <p>
                The x axis indicates when tests were completed and the colored series indicate when tests were latest recalculated.
                We only include tests completed the past 24 months.
                <br/><br/>
                <b>Note: </b>One month is approximated to be 30 days.
            </p>
            <Chart
                type={'stackedcolumn2d'}
                width={'100%'}
                height={500}
                dataFormat={'JSON'}
                dataSource={dataSource}
            />
        </div>
    );
}
