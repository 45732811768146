import {useStopRecalculationJob} from "../../../../../../../api/useStopRecalculationJob";
import {Fragment, useCallback} from "react";
import {GridCellParams} from "@mui/x-data-grid";
import {Button, CircularProgress} from "@material-ui/core";

export function useStopJobLogic(refetchRecalculationJobSummary: () => void) {
    const [doStopJob, {
        success: successfullyStoppedJob,
        error: stopJobError,
        loading: stoppingJob
    }] = useStopRecalculationJob();

    const stopJob = useCallback((recalculationJobId: string) => {
        doStopJob(recalculationJobId);
        refetchRecalculationJobSummary();
    }, [doStopJob, refetchRecalculationJobSummary]);

    const renderStopJobCell = useCallback((params: GridCellParams) => {
        if (params.row['status'] !== 'ACTIVE') {
            return <Fragment/>;
        }

        return (
            <Button
                color={'primary'}
                variant={'outlined'}
                onClick={stopJob.bind(null, params.row['recalculationJobId'] as string)}
            >
                {stoppingJob ? <CircularProgress size={23.5}/> : 'Stop job'}
            </Button>
        );
    }, [stoppingJob, stopJob]);

    return {
        stopJobError,
        successfullyStoppedJob,
        renderStopJobCell
    };
}
