import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {getConfig} from '../../config';
import queryString from 'query-string';
import {v4 as uuid} from "uuid";

const DEFAULT_REDIRECT = '/';

export function Login() {
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    let redirectPath = DEFAULT_REDIRECT;
    if (queryParams.redirect && typeof queryParams.redirect === 'string') {
        redirectPath = queryParams.redirect;
    }

    useEffect(() => {
        redirectToGoogleLogin(redirectPath);
    }, [redirectPath]);

    return <div/>;
}

function redirectToGoogleLogin(redirectPath: string | string[]) {
    const nonce = uuid();
    const scope = window.encodeURIComponent('https://www.googleapis.com/auth/userinfo.email');
    const config = getConfig();
    const state = window.encodeURIComponent(queryString.stringify({redirectPath}));
    const clientId = window.encodeURIComponent(config.GOOGLE_CLIENT_ID);
    const Url = window.encodeURIComponent(`${config.BASE_URL}/verify-google-login`);
    const googleLoginUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=${scope}&include_granted_scopes&client_id=${clientId}&response_type=id_token&redirect_uri=${Url}&nonce=${nonce}&hd=alvalabs.io&state=${state}`;

    window.location.replace(googleLoginUrl);
}
