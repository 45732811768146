import {useCallback, useMemo} from "react";
import {RecalculationJob} from "../../../../../../../api/types/RecalculationJob";
import {getCommonColumnProperties} from "../../../../../utils/tableUtils";
import {GridCellParams} from "@mui/x-data-grid";

export function useTableLogic(renderStopJobCell: (p: GridCellParams) => any) {
    const columns = useMemo(() => [
        {field: 'recalculationJobId', headerName: 'jobId', width: 300},
        {field: 'status'},
        {field: 'createdAt', type: 'date'},
        {field: 'createdBy'},
        {field: 'targetBefore', type: 'date'},
        {field: 'targetAfter', type: 'date'},
        {field: 'targetRecalculatedBefore', type: 'dateTime', width: 300},
        {field: 'theoreticalNumberOfTestsToStart', type: 'number', width: 300},
        {field: 'actualNumberOfTestsToStart', type: 'number', width: 250},
        {field: 'numberOfQueuedTests', type: 'number'},
        {field: 'actions', headerName: 'actions', renderCell: renderStopJobCell}
    ].map(cell => ({...getCommonColumnProperties(200), ...cell})), [renderStopJobCell]);

    const getRows = useCallback((jobs: RecalculationJob[]) => {
        return jobs.map((job: RecalculationJob) => Object.assign({'id': job.recalculationJobId}, job))
    }, []);

    return {
        getRows,
        columns
    };
}
