export function formatError(error: any): string {
    const defaultErrorMessage = 'Something went wrong';
    if (!error) {
        return defaultErrorMessage;
    }

    const {code, message} = error;
    if (message) {
        return message;
    }

    if (code) {
        return code;
    }

    return defaultErrorMessage;
}

export function buildFilterQueryString(comparisonJobId: string, locale?: string, facet?: string): string {
    let queryString = `comparison_job_id=${comparisonJobId}`;
    if (!locale && !facet) {
        return queryString;
    }

    if (!locale) {
        return queryString + `&facet=${facet}`;
    }

    if (!facet) {
        return queryString + `&locale=${locale}`;
    }

    return queryString + `&locale=${locale}&facet=${facet}`;
}
