import {Button} from '@material-ui/core';
import {GridCellParams} from '@mui/x-data-grid';
import {Fragment, useCallback} from 'react';
import {useHistory} from 'react-router-dom';

import {ParameterComparisonJob} from '../../../../../../../api/types/ParameterComparisonJob';
import {LINKS} from '../../../../../links';
import {getCommonColumnProperties} from '../../../../../utils/tableUtils';

interface Arguments {
    openUpdateParametersDialog: (comparisonJobId: any) => void;
    updatingParametersForComparisonJob: string | null;
    stopComparisonJob: () => void;
}

export function useTableLogic(args: Arguments) {
    const onUpdateParametersClick = useCallback(
        (e: any, comparisonJobId: any) => {
            e.preventDefault();
            e.stopPropagation();
            args.openUpdateParametersDialog(comparisonJobId);
        },
        [args]
    );
    const onStopJobClick = useCallback(
        (e: any, comparisonJobId: any) => {
            e.preventDefault();
            e.stopPropagation();
            args.stopComparisonJob();
        },
        [args]
    );

    const renderComparisonJobActionButton = useCallback(
        (params: GridCellParams) => {
            const updatingParametersForJob =
                args.updatingParametersForComparisonJob === params.row['comparisonJobId'];

            const updateParametersJobTriggered = params.value;
            const updateParametersJobIsFinished =
                updateParametersJobTriggered && !updatingParametersForJob;
            const noParameters = params.row['totalParameterCount'] === 0;
            const jobIsActive = params.row['status'] === 'ACTIVE';

            if (jobIsActive) {
                return (
                    <Button
                        color={'primary'}
                        variant={'contained'}
                        onClick={(e: any) => onStopJobClick(e, params.row['comparisonJobId'])}
                    >
                        Cancel job
                    </Button>
                );
            }

            if (noParameters || updateParametersJobIsFinished) {
                return <Fragment />;
            }

            if (updatingParametersForJob) {
                return <Fragment>Updating parameters...</Fragment>;
            }

            return (
                <Button
                    color={'primary'}
                    variant={'contained'}
                    onClick={(e: any) =>
                        onUpdateParametersClick(e, params.row['comparisonJobId'])
                    }
                >
                    Upload parameters
                </Button>
            );
        },
        [args, onUpdateParametersClick, onStopJobClick]
    );

    const columns = [
        {field: 'comparisonJobId', width: 300},
        {field: 'createdAt', type: 'date'},
        {field: 'status'},
        {field: 'createdBy'},
        {field: 'tableName'},
        {field: 'totalParameterCount', type: 'number', width: 200},
        {field: 'processedParameterCount', type: 'number', width: 200},
        {
            field: 'updateParametersJobTriggered',
            headerName: 'actions',
            renderCell: renderComparisonJobActionButton,
            width: 250
        }
    ].map(cell => ({...getCommonColumnProperties(150), ...cell}));

    const rows = useCallback((jobs: ParameterComparisonJob[]) => {
        return jobs.map((job: ParameterComparisonJob) => {
            return Object.assign({id: job.comparisonJobId}, job);
        });
    }, []);

    const history = useHistory();
    const handleSelection = useCallback(
        (s: any) => {
            const comparisonJobId = s[0];
            history.push(LINKS.comparisonJobResults(comparisonJobId));
        },
        [history]
    );

    return {columns, rows, handleSelection};
}
