import {GridRowModel} from '@mui/x-data-grid';
import {useCallback, useEffect, useState} from 'react';

import {Question} from '../../../../../../api/types/Question';
import {useCreateQuestion} from '../../../../../../api/useCreateQuestion';
import {useQuestions} from '../../../../../../api/useQuestions';
import {useUpdateQuestion} from '../../../../../../api/useUpdateQuestion';
import {useSnackbar} from '../../../../../../hooks/useSnackbar';
import {CreateQuestionFormData} from './components/CreateQuestionModal';
import {useLocaleAndStatusFilterLogic} from './components/LocaleAndQuestionGlobalIdFilter/logic';

export const useQuestionsLogic = () => {
    const {selectLocale, selectStatus, locale, status, clearLocale, clearStatus} =
        useLocaleAndStatusFilterLogic();
    const {
        questions: originalQuestions,
        loading: originalLoading,
        error: originalError
    } = useQuestions(locale);
    const {
        questions: englishQuestions,
        loading: englishLoading,
        error: englishError
    } = useQuestions('EN');

    let questionsWithEnglishTitle = originalQuestions;
    if (englishQuestions && originalQuestions) {
        questionsWithEnglishTitle = addEnglishTitle(englishQuestions, originalQuestions);
    }

    const error = originalError || englishError;
    const loading = originalLoading || englishLoading;

    const [updatedRows, setUpdatedRows] = useState<Record<string, GridRowModel>>({});
    const [currentRowId, setCurrentRowId] = useState<string | null>(null);

    const [doUpdateQuestion, {success: updateQuestionSuccess, error: updateQuestionError}] =
        useUpdateQuestion();
    const [doCreateQuestion, {success: createQuestionSuccess, error: createQuestionError}] =
        useCreateQuestion();

    // Create question
    const [openCreateQuestionModal, setCreateQuestionModal] = useState(false);
    const handleOpenCreateQuestionModal = () => {
        setCreateQuestionModal(true);
    };
    const handleCloseCreateQuestionModal = () => {
        setCreateQuestionModal(false);
    };
    const handleSubmitCreateQuestion = useCallback(
        async (data: CreateQuestionFormData) => {
            await doCreateQuestion(data.global_id, data.locale, data.title);
        },
        [doCreateQuestion]
    );
    const {snackbar: createSnackbar, handleCloseSnackbar: handleCloseCreateSnackbar} =
        useSnackbar(
            createQuestionError,
            createQuestionSuccess ? 'Row successfully created' : null
        );

    // Update question
    const processRowUpdate = useCallback(
        async (newRow: GridRowModel<Question>, oldRow: GridRowModel<Question>) => {
            if (newRow.title !== oldRow.title || newRow.status !== oldRow.status) {
                setUpdatedRows(currentRows => {
                    return {...currentRows, [newRow.id]: newRow};
                });
            }
            return newRow;
        },
        [setUpdatedRows]
    );
    const commitRow = useCallback(
        async (newRow: GridRowModel<Question>) => {
            setCurrentRowId(newRow.id);
            const response = await doUpdateQuestion(newRow.id, newRow.title, newRow.status);

            return response;
        },
        [doUpdateQuestion]
    );
    useEffect(() => {
        if (updateQuestionSuccess && currentRowId) {
            setUpdatedRows(currentRows => {
                const newRows = {...currentRows};
                delete newRows[currentRowId];
                return newRows;
            });
        }
    }, [updateQuestionSuccess, currentRowId]);
    const onProcessRowUpdateError = () => null;
    const {snackbar: updateSnackbar, handleCloseSnackbar: handleCloseUpdateSnackbar} =
        useSnackbar(
            updateQuestionError,
            updateQuestionSuccess ? 'Row successfully saved' : null
        );

    return {
        questionsWithEnglishTitle,
        error,
        loading,
        selectStatus,
        locale,
        selectLocale,
        status,
        clearStatus,
        clearLocale,
        updatedRows,
        processRowUpdate,
        onProcessRowUpdateError,
        updateSnackbar,
        createSnackbar,
        handleCloseUpdateSnackbar,
        handleCloseCreateSnackbar,
        commitRow,
        openCreateQuestionModal,
        handleOpenCreateQuestionModal,
        handleCloseCreateQuestionModal,
        handleSubmitCreateQuestion
    };
};

const addEnglishTitle = (
    enQuestions: Question[],
    originalQuestions: Question[]
): Question[] => {
    // Create a map of globalId to English title for fast lookup
    const enTitleMap = new Map<string, string>();
    for (const obj of enQuestions) {
        enTitleMap.set(obj.globalId, obj.title);
    }

    // Add the English title to the second list based on the globalId match
    for (const obj of originalQuestions) {
        if (enTitleMap.has(obj.globalId)) {
            obj.englishTitle = enTitleMap.get(obj.globalId);
        }
    }

    return originalQuestions;
};
