import {useMemo} from "react";
import {parseRecalculationJobSummary} from "./parse";
import {RecalculationJobSummary} from "./types/RecalculationJobSummary";
import {useApiCall} from "./useApiCall";

interface Return {
    recalculationJobSummary?: RecalculationJobSummary | null;
    loading: boolean;
    error: string | null;
    refetch: () => void
}

export function useRecalculationJobSummary(): Return {
    const url = '/api/recalculationJobs.list';
    const {data, error, loading, refetch} = useApiCall(url);
    const recalculationJobSummary = useMemo(() => {
        if (data) {
            return parseRecalculationJobSummary(data);
        }
        return null;
    }, [data]);

    return {
        recalculationJobSummary,
        loading,
        error,
        refetch
    }
}
