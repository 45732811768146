import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import styled from "styled-components";
import {InputLabel} from "@material-ui/core";

interface TableSelectorProps {
    loading: boolean;
    error: string | null;
    tableIds: string[];
    value: string | null;
    onSelect: (e: any) => void;
}

export function TableSelector({loading, error, tableIds, value, onSelect}: TableSelectorProps) {
    if (error) {
        return <h3>Failed to load BigQuery tables: {error}. Try refreshing the page.</h3>;
    }

    if (!loading && tableIds.length === 0) {
        <h3>No tables in BigQuery dataset. Add a table there and try again.</h3>;
    }

    return (
        <Wrapper>
            <Label id="select-label">Select BigQuery table</Label>
            <Select
                required
                value={value || 'Select ...'}
                onChange={onSelect}
                disabled={loading}
                inputProps={{
                    name: 'selectTableId',
                    id: 'select-table-id'
                }}
                autoWidth={true}
            >
                <MenuItem value={'Select ...'} style={{display: 'none'}}>Select ...</MenuItem>
                {tableIds.map(tableId => (
                    <MenuItem key={`option-${tableId}`} value={tableId}>{tableId}</MenuItem>
                ))}
            </Select>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 32px;
  min-width: 150px;
  flex: 1;
`;

const Label = styled(InputLabel)`
  margin-bottom: 12px;
`;
