import {useCallback} from "react";
import {useApiOperation} from "./useApiOperation";


interface CreateUpdateParametersJobStatus {
    success: boolean;
    loading: boolean;
    error: string | null;
}

type CreateUpdateParametersJobReturn = [(comparisonJobId: string | null) => Promise<unknown>, CreateUpdateParametersJobStatus];

export function useCreateUpdateParametersJob(): CreateUpdateParametersJobReturn {
    const url = '/api/updateParametersJobs.create';
    const [request, {error, loading, success}] = useApiOperation(url);

    const makeRequest = useCallback((comparisonJobId: string | null) => {
        const payload = {comparisonJobId};
        return request(payload);
    }, [request]);

    return [makeRequest, {
        success,
        loading,
        error
    } as CreateUpdateParametersJobStatus]
}
