import {Redirect, Route, Switch} from 'react-router-dom';

import {LINKS} from '../../links';
import {QuestionOverview} from './pages/QuestionOverview';
import {ImportQuestions} from "./pages/ImportQuestions";

export const Questions = () => (
    <Switch>
        <Route exact path={LINKS.questions()}>
            <QuestionOverview />
        </Route>
        <Route path={LINKS.importQuestions()}>
            <ImportQuestions/>
        </Route>
        <Route path="*">
            <Redirect to={LINKS.questions()} />
        </Route>
    </Switch>
);
