import {TOAST_TYPES, Toast} from '../../../../../../components/Toast';
import {RecentComparisonJobs} from '../RecentComparisonJobs';
import {useStopComparisonJobLogic} from '../RecentComparisonJobs/hooks/useStopComparisonJob';
import {ActiveUpdateParametersJobProgress} from './components/ActiveUpdateParametersJobProgress';
import {useActiveUpdateParametersJobProgressLogic} from './components/ActiveUpdateParametersJobProgress/logic';
import {UpdateParametersDialog} from './components/UpdateParametersDialog';
import {useUpdateParametersLogic} from './hooks/useUpdateParametersLogic';

export function ComparisonJobs() {
    const {
        dialogIsOpen,
        openDialog,
        closeDialog,
        createJob,
        success: createUpdateParametersJobSuccess,
        error: createUpdateParametersJobError,
        updatingParameters
    } = useUpdateParametersLogic();
    const {progress: activeUpdateParametersJobProgress} =
        useActiveUpdateParametersJobProgressLogic();
    const {stopComparisonJob, successfullyStoppedComparisonJob, stopComparisonJobError} =
        useStopComparisonJobLogic();

    const error = createUpdateParametersJobError || stopComparisonJobError;

    return (
        <div>
            <ActiveUpdateParametersJobProgress progress={activeUpdateParametersJobProgress} />
            <RecentComparisonJobs
                openUpdateParametersDialog={openDialog}
                updatingParametersForComparisonJob={
                    activeUpdateParametersJobProgress
                        ? activeUpdateParametersJobProgress.comparisonJobId
                        : null
                }
                stopComparisonJob={stopComparisonJob}
            />
            {createUpdateParametersJobSuccess && (
                <Toast
                    type={TOAST_TYPES.SUCCESS}
                    message={'Successfully created job to update parameters'}
                />
            )}
            {successfullyStoppedComparisonJob && (
                <Toast
                    type={TOAST_TYPES.SUCCESS}
                    message={'Successfully stopped comparison job'}
                />
            )}
            {error && <Toast type={TOAST_TYPES.ERROR} message={error} />}
            <UpdateParametersDialog
                isOpen={dialogIsOpen}
                close={closeDialog}
                createJob={createJob}
                updatingParameters={updatingParameters}
            />
        </div>
    );
}
