import MenuItem from "@material-ui/core/MenuItem";
import styled from "styled-components";
import { Select, IconButton } from '@material-ui/core';
import ClearIcon from "@material-ui/icons/Clear";
import { Locale } from '../../../../../api/types/Locale';
import { DataCollectionMode } from '../constants';

interface DataCollectionModeSelectProps {
    locale: Locale;
    updateDataCollectionMode: (localeId: string, dataCollectionMode: DataCollectionMode) => void;
    clearDataCollectionMode: (localeId: string) => void;
}

export function DataCollectionModeSelect({locale, updateDataCollectionMode, clearDataCollectionMode}: DataCollectionModeSelectProps) {
    return (
        <DataCollectionModeSelectWrapper>
            <SelectWrapper 
                value={locale.dataCollectionMode}
                onChange={e => updateDataCollectionMode(locale.id, DataCollectionMode[e.target.value as keyof typeof DataCollectionMode])}
            >
                {Object.entries(DataCollectionMode).map((mode) => (
                    <MenuItem key={`collection-mode-option-${mode}`} value={mode[0]}>{mode[1]}</MenuItem>
                ))}
            </SelectWrapper>
            <IconButton onClick={_ => clearDataCollectionMode(locale.id)}>
                <ClearIcon/>
            </IconButton>
        </DataCollectionModeSelectWrapper>
    )
}

const DataCollectionModeSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;

const SelectWrapper = styled(Select)`
  margin-right: 20px;
  width: 100%;
`;
