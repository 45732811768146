import {useMemo} from "react";
import {useApiCall} from "./useApiCall";
import {parseUpdateParametersJobProgress} from "./parse";
import {UpdateParametersJobProgress} from "./types/UpdateParametersJobProgress";

interface ActiveUpdateParametersJobProgressReturn {
    progress: UpdateParametersJobProgress | null;
    loading: boolean;
    error: string | null;
    refetch: () => void;
}

export function useActiveUpdateParametersJobProgress(): ActiveUpdateParametersJobProgressReturn {
    const url = `/api/updateParametersJobs.getActiveJobProgress`;
    const {data, error, loading, refetch} = useApiCall(url);

    const progress = useMemo(() => {
        if (data) {
            return parseUpdateParametersJobProgress(data.progress);
        }

        return null;
    }, [data]);

    return {
        progress,
        loading,
        error,
        refetch
    };
}
