import {useAvailableTableIds} from "../../../../../../../api/useAvailableTableIds";
import {useCallback, useState} from "react";

export function useSelectTableIdLogic() {
    const {tableIds, loading: loadingTableIds, error: tableIdsError} = useAvailableTableIds();
    const [selectedTableId, setSelectedTableId] = useState<string | null>(null);

    const handleSelect = useCallback((e: any) => {
        const {value} = e.target || {};
        setSelectedTableId(value || null);
    }, []);

    return {tableIds, loadingTableIds, tableIdsError, selectedTableId, handleSelect};
}
