import {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import queryString from 'query-string';
import {parseUrl} from "../../config";
import {setToken} from "../../services/auth/helpers";
import {Path} from "typescript";
import {Spinner} from "../../components/Spinner";

export function VerifyGoogleLogin() {
    let [isVerified, setIsVerified] = useState(false);
    let [loading, setLoading] = useState(true);
    const location = useLocation();
    const history = useHistory();
    const hashParams = queryString.parse(location.hash);
    // @ts-ignore
    const state = queryString.parse(window.decodeURIComponent(hashParams.state));

    const idToken = hashParams.id_token;
    const googleError = hashParams.error;


    useEffect(() => {
        const goToHomepage = () => {
            // @ts-ignore
            setTimeout(() => history.push('/'), 3000);
        };

        fetch(
            parseUrl(`/api/login?id_token=${idToken}`),
            {
                method: "POST",
                headers: new Headers({
                    Accept: "application/json"
                })
            }
        ).then(async res => {
            setLoading(false);
            const response = await res.json();
            if (response.data.ok) {
                setIsVerified(true);
                setToken(response.data.token);
                if (state.redirectPath) {
                    history.push(state.redirectPath as Path);
                }
            } else {
                goToHomepage();
            }
        }).catch(error => {
            setLoading(false);
            console.log(error);
            goToHomepage();
        });
    }, [idToken, history, state.redirectPath]);

    if (googleError) {
        return <div>
            {googleError}
        </div>;
    }


    return loading ? <Spinner/> : (
        <div>
            {!isVerified &&
            <p>You do not have sufficient permissions to access this page. Redirecting to home page in 3 seconds.</p>}
        </div>
    );
}
