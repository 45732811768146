import {useSelectTableIdLogic} from "./hooks/useSelectTableIdLogic";
import {useCreateComparisonJobLogic} from "./hooks/useCreateComparisonJobLogic";
import {TableSelector} from "./components/TableSelector";
import {CreateComparisonJobButton} from "./components/CreateComparisonJobButton";
import {BigQueryDatasetInfo} from "../BigQueryDatasetInfo";
import {RequiredColumns} from "../RequiredColumns";
import {Toast, TOAST_TYPES} from "../../../../../../components/Toast";
import styled from 'styled-components';

export function CreateComparisonJob() {
    const {
        tableIds,
        loadingTableIds,
        tableIdsError,
        selectedTableId,
        handleSelect
    } = useSelectTableIdLogic();
    const {
        createComparisonJob,
        creatingComparisonJob,
        successFullyCreatedComparisonJob,
        createComparisonJobError
    } = useCreateComparisonJobLogic({selectedTableId});

    return (
        <div>
            <h2>Compare existing parameters to new parameters from BigQuery</h2>
            <br/>
            <InfoWrapper>
                <BigQueryDatasetInfo/>
                <RequiredColumns/>
                <FlexBox>
                    <TableSelector
                        loading={loadingTableIds}
                        error={tableIdsError}
                        tableIds={tableIds}
                        value={selectedTableId}
                        onSelect={handleSelect}
                    />
                    {selectedTableId && (
                        <CreateComparisonJobButton
                            loading={creatingComparisonJob}
                            error={createComparisonJobError}
                            onClick={createComparisonJob}
                        />
                    )}
                </FlexBox>
            </InfoWrapper>
            {successFullyCreatedComparisonJob && (
                <Toast type={TOAST_TYPES.SUCCESS} message={'Successfully created comparison job!'}/>
            )}
        </div>
    )
}

const InfoWrapper = styled.div`
  width: 400px;
  margin: 0 auto;
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;
