import {useCallback} from "react";
import {useApiOperation} from "./useApiOperation";


type Return = [(recalculationJobId: string) => void, {
    loading: boolean;
    error?: string | null;
    success: boolean;
}]

export function useStopRecalculationJob(): Return {
    const url = '/api/recalculationJobs.stop';
    const [request, {error, loading, success}] = useApiOperation(url);

    const stopRecalculationJob = useCallback((recalculationJobId: string) => {
        const payload = {recalculationJobId};
        return request(payload);
    }, [request]);

    return [stopRecalculationJob, {
        loading,
        error,
        success
    }]
}
