import {useCallback, useEffect} from "react";
import {useCreateComparisonJob} from "../../../../../../../api/useCreateComparisonJob";
import {LINKS} from "../../../../../links";
import {useHistory} from "react-router-dom";

interface Arguments {
    selectedTableId: string | null;
}

export function useCreateComparisonJobLogic({selectedTableId}: Arguments) {
    const [
        doCreateComparisonJob,
        {comparisonJobId, loading: creatingComparisonJob, error: createComparisonJobError}
    ] = useCreateComparisonJob(selectedTableId);

    const createComparisonJob = useCallback(() => {
        if (!selectedTableId) {
            return;
        }

        doCreateComparisonJob().then(() => console.log('Created comparison job'));
    }, [doCreateComparisonJob, selectedTableId]);

    const history = useHistory();
    useEffect(() => {
        if (comparisonJobId) {
            history.push(LINKS.comparisonJobs());
        }
    }, [comparisonJobId, history]);

    return {
        createComparisonJob,
        creatingComparisonJob,
        successFullyCreatedComparisonJob: !!comparisonJobId,
        createComparisonJobError,
    };
}
