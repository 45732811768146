import {useCallback} from 'react';

import {useApiOperation} from './useApiOperation';

type CreateQuestions = {
    loading: boolean;
    success: boolean;
    error: string | null;
};

type CreateQuestionReturn = [
    (global_id: string, locale: string, title: string) => Promise<unknown>,
    CreateQuestions
];

export const useCreateQuestion = (): CreateQuestionReturn => {
    const baseUrl = `/api/questions.create`;
    const [request, {error, loading, success}] = useApiOperation(baseUrl, {method: 'POST'});

    const makeRequest = useCallback(
        (global_id: string, locale: string, title: string) => {
            const payload = {global_id, locale, title};
            return request(payload);
        },
        [request]
    );

    return [
        makeRequest,
        {
            loading,
            success,
            error
        } as CreateQuestions
    ];
};
