import {useMemo} from "react";
import {formatDate} from "../../../../../../../../../utils";

interface Arguments {
    fromDate: string | Date;
    toDate: string | Date;
    recalculatedBeforeDate: string | Date;
}

export function useFormatDatesLogic({fromDate, toDate, recalculatedBeforeDate}: Arguments) {
    const formattedFromDate = useMemo(() => formatDate(fromDate), [fromDate]);
    const formattedToDate = useMemo(() => formatDate(toDate), [toDate]);
    const formattedRecalculatedBeforeDate = useMemo(() => {
        return formatDate(recalculatedBeforeDate, true);
    }, [recalculatedBeforeDate]);
    const allDatesAreSet = useMemo(() => {
        return !!formattedFromDate && !!formattedToDate && !!formattedRecalculatedBeforeDate;
    }, [formattedFromDate, formattedToDate, formattedRecalculatedBeforeDate]);

    return {formattedFromDate, formattedToDate, formattedRecalculatedBeforeDate, allDatesAreSet};
}
