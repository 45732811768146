import {QuestionDetails} from "./types/QuestionDetails";
import {ParameterComparisonJob} from "./types/ParameterComparisonJob";
import {ParameterComparison} from "./types/ParameterComparison";
import {UpdateParametersJobProgress} from "./types/UpdateParametersJobProgress";
import {ParameterAgeMetrics} from "./types/ParameterAgeMetrics";
import {RecalculationJobSummary} from "./types/RecalculationJobSummary";
import {RecalculationJob} from "./types/RecalculationJob";
import {ParameterComparisonSummary} from "./types/ParameterComparisonSummary";
import {Locale} from "./types/Locale";
import {Question} from "./types/Question";

export function parseQuestion(responseData: any): Question[] {
    return responseData.map((question: Question) => {
        return {
            id: question.id,
            created: new Date(question.created),
            modified: new Date(question.modified),
            globalId: question.globalId,
            title: question.title,
            localeId: question.localeId,
            locale: question.locale,
            status: question.status,
        };
    });
}
export function parseQuestionDetails(responseData: any): QuestionDetails[] {
    return responseData.map((details: QuestionDetails) => {
        return {
            globalId: details.globalId,
            locale: details.locale,
            facet: details.facet,
            status: details.status,
            alpha: details.alpha,
            kappa1: details.kappa1,
            kappa2: details.kappa2,
            kappa3: details.kappa3,
            kappa4: details.kappa4,
            alphaSigma: details.alphaSigma,
            kappa1Sigma: details.kappa1Sigma,
            kappa2Sigma: details.kappa2Sigma,
            kappa3Sigma: details.kappa3Sigma,
            kappa4Sigma: details.kappa4Sigma,
            positiveKey: parseBooleanValue(details.positiveKey),
            updatedAt: new Date(details.updatedAt)
        };
    });
}

export function parseComparisonJobs(responseData: any): ParameterComparisonJob[] {
    return responseData.parameterComparisonJobs.map((parameterComparisonJob: ParameterComparisonJob) => {
        return {
            createdAt: new Date(parameterComparisonJob.createdAt),
            createdBy: parameterComparisonJob.createdBy,
            comparisonJobId: parameterComparisonJob.comparisonJobId,
            tableName: parameterComparisonJob.tableName,
            totalParameterCount: parameterComparisonJob.totalParameterCount,
            processedParameterCount: parameterComparisonJob.processedParameterCount,
            status: parameterComparisonJob.status,
            updateParametersJobTriggered: parseBooleanValue(parameterComparisonJob.updateParametersJobTriggered)
        };
    });
}

export function parseParameterComparisons(responseData: any): ParameterComparison[] {
    return responseData.map((parameterComparison: ParameterComparison) => {
        return {
            globalId: parameterComparison.globalId,
            locale: parameterComparison.locale,
            currentFacet: parameterComparison.currentFacet,
            currentPositiveKey: parseBooleanValue(parameterComparison.currentPositiveKey),
            currentAlpha: parameterComparison.currentAlpha,
            currentKappa1: parameterComparison.currentKappa1,
            currentKappa2: parameterComparison.currentKappa2,
            currentKappa3: parameterComparison.currentKappa3,
            currentKappa4: parameterComparison.currentKappa4,
            currentAlphaSigma: parameterComparison.currentAlphaSigma,
            currentKappa1Sigma: parameterComparison.currentKappa1Sigma,
            currentKappa2Sigma: parameterComparison.currentKappa2Sigma,
            currentKappa3Sigma: parameterComparison.currentKappa3Sigma,
            currentKappa4Sigma: parameterComparison.currentKappa4Sigma,
            currentStatus: parameterComparison.currentStatus,
            newFacet: parameterComparison.newFacet,
            newPositiveKey: parseBooleanValue(parameterComparison.newPositiveKey),
            newAlpha: parameterComparison.newAlpha,
            newKappa1: parameterComparison.newKappa1,
            newKappa2: parameterComparison.newKappa2,
            newKappa3: parameterComparison.newKappa3,
            newKappa4: parameterComparison.newKappa4,
            newAlphaSigma: parameterComparison.newAlphaSigma,
            newKappa1Sigma: parameterComparison.newKappa1Sigma,
            newKappa2Sigma: parameterComparison.newKappa2Sigma,
            newKappa3Sigma: parameterComparison.newKappa3Sigma,
            newKappa4Sigma: parameterComparison.newKappa4Sigma,
            newStatus: parameterComparison.newStatus,
            willBeUpdated: parseBooleanValue(parameterComparison.willBeUpdated),
            tooBigAlphaChange: parseBooleanValue(parameterComparison.tooBigAlphaChange),
            tooBigKappaChange: parseBooleanValue(parameterComparison.tooBigKappaChange),
            strangeStatusChange: parseBooleanValue(parameterComparison.strangeStatusChange),
            positiveKeyChanged: parseBooleanValue(parameterComparison.positiveKeyChanged)
        };
    });
}

export function parseParameterComparisonSummary(responseData: any): ParameterComparisonSummary[] {
    return responseData.map((parameterComparisonSummary: ParameterComparisonSummary) => {
        return {
            locale: parameterComparisonSummary.locale,
            facet: parameterComparisonSummary.facet,
            questionsWithWarning: parameterComparisonSummary.questionsWithWarning,
            updatedQuestions: parameterComparisonSummary.updatedQuestions,
            activeQuestions: parameterComparisonSummary.activeQuestions
        };
    });
}

export function parseUpdateParametersJobProgress(responseData: any): UpdateParametersJobProgress {
    return {
        comparisonJobId: responseData.comparisonJobId,
        processedParameterCount: responseData.processedParameterCount,
        totalParameterCount: responseData.totalParameterCount,
        percentageProgress: responseData.percentageProgress
    };
}

export function parseParameterAgeMetrics(responseData: any): ParameterAgeMetrics[] {
    return responseData.map((metrics: any) => {
        return {
            period: metrics.period,
            youngerThanOneMonth: metrics.youngerThanOneMonth,
            youngerThanThreeMonths: metrics.youngerThanThreeMonths,
            youngerThanSixMonths: metrics.youngerThanSixMonths,
            youngerThanTwelveMonths: metrics.youngerThanTwelveMonths,
            olderThanTwelveMonths: metrics.olderThanTwelveMonths
        };
    });
}

export function parseRecalculationJobSummary(responseData: any): RecalculationJobSummary {
    return {
        activeJobRecalculatedTests: responseData.activeJobRecalculatedTests,
        jobs: responseData.jobs.map((job: any) => parseRecalculationJob(job))
    }
}

function parseRecalculationJob(responseData: any): RecalculationJob {
    return {
        recalculationJobId: responseData.recalculationJobId,
        createdAt: new Date(responseData.createdAt),
        createdBy: responseData.createdBy,
        targetBefore: new Date(responseData.targetBefore),
        targetAfter: new Date(responseData.targetAfter),
        targetRecalculatedBefore: new Date(responseData.targetRecalculatedBefore),
        status: responseData.status,
        theoreticalNumberOfTestsToStart: responseData.theoreticalNumberOfTestsToStart,
        actualNumberOfTestsToStart: responseData.actualNumberOfTestsToStart,
        numberOfQueuedTests: responseData.numberOfQueuedTests
    }
}

export function parseLocales(responseData: any): Locale[] {
    return responseData.locales.map(parseLocale);
}

export function parseLocale(locale: any): Locale {
    return {
        id: locale.id,
        name: locale.name,
        isDataCollectionEnabled: locale.isDataCollectionEnabled,
        dataCollectionMode: locale.dataCollectionMode
    }
}

function parseBooleanValue(value: any): boolean | null {
    if (value === undefined || value === null) {
        return null;
    }

    return Boolean(value);
}
