import {useCallback, useMemo} from "react";
import {useApiOperation} from "./useApiOperation";


interface CreateComparisonJobStatus {
    comparisonJobId: string | null;
    loading: boolean;
    error: string | null;
}

type CreateComparisonJobReturn = [() => Promise<unknown>, CreateComparisonJobStatus];

export function useCreateComparisonJob(tableName: string | null): CreateComparisonJobReturn {
    const url = '/api/parameterComparisonJobs.create';
    const [request, {data, error, loading}] = useApiOperation(url);

    const comparisonJobId = useMemo(() => {
        return data && data.comparisonJobId ? data.comparisonJobId : null;
    }, [data]);

    const makeRequest = useCallback(() => {
        const payload = {tableName};
        return request(payload);
    }, [request, tableName]);

    return [makeRequest, {
        comparisonJobId,
        loading,
        error
    } as CreateComparisonJobStatus]
}
