import {useLocation} from "react-router-dom";
import {useMemo} from "react";
import queryString from "query-string";

export function useHaActiveJobLogic() {
    const location = useLocation();
    return useMemo(() => {
        const queryParams = queryString.parse(location.search);
        return queryParams.hasActiveJob === 'true';
    }, [location.search]);
}
