import styled from "styled-components";
import {useBigQueryDatasetUrl} from "../../../../../api/useBigQueryDatasetUrl";

export function BigQueryDatasetInfo() {
    const {url} = useBigQueryDatasetUrl();

    return (
        <Info>
            Upload a BigQuery table in the dataset located&nbsp;
            <Link isDisabled={!url} href={url} target="_blank" rel="noopener noreferrer">here.</Link>&nbsp;
            The table must include the required columns listed below.
            <br/><br/>
            When the table exists, select it in the dropdown below and click "compare".
        </Info>
    );
}

const Info = styled.p`
  margin: 0 auto;
  text-align: left;
`;

const Link = styled.a<{isDisabled: boolean;}>`
  ${({isDisabled}) => isDisabled ? `
    pointer-events: none;
    cursor: not-allowed;
  ` : ''};
`;
