import {useCallback, useState} from "react";

export function useFilterLogic() {
    const [facet, setFacet] = useState<string>('');
    const [locale, setLocale] = useState<string>('');

    const selectFacet = useCallback((e: any) => {
        setFacet(e.target.value);
    }, []);

    const selectLocale = useCallback((e: any) => {
        setLocale(e.target.value);
    }, []);

    const clearFacet = useCallback(() => {
        setFacet('');
    }, []);

    const clearLocale = useCallback(() => {
        setLocale('');
    }, []);

    return {facet, locale, selectFacet, selectLocale, clearFacet, clearLocale};
}
