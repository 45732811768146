import {Fragment} from "react";
import {Button, CircularProgress} from "@material-ui/core";
import {Toast, TOAST_TYPES} from "../../../../../../../components/Toast";

interface CreateComparisonJobProps {
    loading: boolean;
    error: string | null;
    onClick: () => void;
}

export const CreateComparisonJobButton = ({loading, error, onClick}: CreateComparisonJobProps) => (
    <Fragment>
        <Button
            color={'primary'}
            variant={'contained'}
            disabled={loading}
            onClick={onClick}
            style={{maxHeight: 40, width: 108}}
        >
            {loading ? <CircularProgress size={23.5}/> : 'Compare'}
        </Button>
        {error && <Toast type={TOAST_TYPES.ERROR} message={error}/>}
    </Fragment>
);
